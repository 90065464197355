import React, { useState } from "react"
import Button from "@app/components/Button"
import { Checkbox, Radio, useForm } from "@app/components/Form"
import Modal from "@app/components/Modal"
import { H3, Text } from "@app/components/Typography"
import { Col } from "@app/components/Layout"
import { useDispatch } from "@app/models"
import api from "@app/services/api"
import { handleApiError } from "@app/utils"
import { formatDate } from "@app/utils"
// import css from "./DraftPublish.module.scss"

import { MultipleAssigmentJob } from "../../data"

interface DraftPublishProps {
  item: MultipleAssigmentJob
  edate: string
  showPublish: React.Dispatch<
    React.SetStateAction<MultipleAssigmentJob | undefined>
  >
}

// type PropsType = {
//   draft_eventid: number
//   jobid: number
//   job_abbrev: string
//   date: string
// }

type FormFieldsType = {
  notify_schedulers: boolean
  notify_providers: string
}

export default ({ item, edate, showPublish }: DraftPublishProps) => {
  const { job: job_abbrev, jobid } = item
  const date = edate
  const formatedDate = formatDate(date, (f) => f.shortSlash)

  const dispatch = useDispatch()

  const [showNotifyProviderOptions, setShowNotifyProviderOptions] =
    useState(false)
  const { control, handleSubmit } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.lazy(() =>
        yup.object().shape({
          notify_providers: yup.string(),
          notify_schedulers: yup.boolean(),
        })
      ),
  })

  const submitForm = (data: FormFieldsType) => {
    const { notify_providers, notify_schedulers } = data
    api
      .publishDraftEvents({
        jobids: [parseInt(jobid)],
        start_date: date,
        end_date: date,
        notify_scheduled_providers:
          showNotifyProviderOptions &&
          notify_providers === "notifyScheduledProviders",
        notify_all_providers:
          showNotifyProviderOptions &&
          notify_providers === "notifyAllProviders",
        notify_schedulers,
      })
      .then((res) => {
        dispatch.calendarEvents.getEvents()
        dispatch.calendarEvents.getDraftEvents()
        showPublish(undefined)
      }, handleApiError)
  }

  const modalTitle = item.multiple_assigment
    ? `Publish Assignments on ${formatedDate}`
    : `Publish Assignment on ${formatedDate}`

  return (
    <Modal
      title={modalTitle}
      show={true}
      onHide={showPublish}
      style={{ zIndex: 2000 }}
    >
      <div className="bootstrap4">
        <Col className="mb-3">
          <H3 bold="medium">Job</H3>
          <Text>{job_abbrev}</Text>
        </Col>
        <Col className="mb-3">
          <H3 bold="medium">Date</H3>
          <Text>{formatedDate}</Text>
        </Col>
        <form onSubmit={handleSubmit(submitForm)}>
          <Col>
            <Checkbox
              label="Notify providers"
              name="notify_scheduled_providers"
              onChange={(e) => setShowNotifyProviderOptions(e.target.checked)}
              className="mb-3"
            />
          </Col>
          {showNotifyProviderOptions && (
            <Col className="mb-3 ml-5 text-nowrap">
              <Radio.Group
                name="notify_providers"
                control={control}
                options={[
                  {
                    id: "notifyScheduledProviders",
                    name: item.multiple_assignments
                      ? "Notify the providers assigned to this job"
                      : "Notify the provider assigned to this job",
                  },
                  {
                    id: "notifyAllProviders",
                    name: "Notify all providers in the group",
                  },
                ]}
                defaultValue="notifyScheduledProviders"
              />
            </Col>
          )}
          <Col>
            <Checkbox
              label="Notify all schedulers"
              name="notify_schedulers"
              control={control}
              className="mb-3"
            />
          </Col>
          <div className="d-flex flex-row-reverse">
            <Button className="mr-1" variant="primary" type="submit">
              Publish
            </Button>
            <Button
              className="mr-3"
              variant="outline-secondary"
              onClick={() => showPublish(undefined)}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}
