import React, { useState, useEffect, useRef } from "react"
import { cloneDeep } from "lodash"
import css from "./CalendarGridView.module.scss"
import AddIcon from "@material-ui/icons/Add"
import AssignmentContentModal from "./AssignmentContentModal/AssignmentContentModal"
import { format, parseISO, isSameDay } from "date-fns"
import JobsTableModal from "./JobsTableModal/JobsTableModal"
import RequestModal from "./RequestModal"
import cx from "classnames"
import { getGroupedDayAssignments } from "@app/services/getGroupedDayAssignments"
import {
  AssignmentElement,
  RequestElement,
  DayOffElement,
  VacationElement,
  UnassignedElement,
} from "./CalendarDayElements"
import {
  Provider,
  MultipleAssigmentJob,
  IRequest,
} from "@app/containers/spa/WhiteboardCalendar/data"
import {
  ProcessedHoliday,
  getHolidaysOffDate,
} from "@app/services/getOrderedHolidays"
import { DaysOfWeek, MonthsAbbrev } from "@app/utils/constants"
import { Flag } from "@material-ui/icons"
import { transformsDataMultipleAssigmentJobs } from "@app/services/transformDataMultipleAssignment"
import { filterUnassignedJobs } from "@app/services/getUnassignedEvent"
import { useSelector } from "@app/models"
import NotesModal from "./NotesModal"
import { NoteElement } from "./CalendarDayElements/NoteElement/NoteElement"
import { getDailyNotes } from "@app/services/getDailyNotes"

interface CalendarProps {
  view: "Day" | "Week" | "Month"
  selectedDate: string
  periodCount: number
  assignments: AssignmentBaseType[]
  jobsFilteredAssignments: AssignmentBaseType[]
  flags: ScheduleDateType[]
  dayoffs: Dayoff[]
  vacations: Vacation[]
  notes: Note[]
  requests: IRequest[]
  providers: Provider[]
  jobs: JobAssignment[]
  filteredJobs: JobAssignment[]
  daysOffTypes: any
  requestStatus: any
  orderedHolidays: ProcessedHoliday[]
  refreshAssignments: () => void
  refreshFlags: () => void
}

export const getDayAssignments = (
  assignmentsData: AssignmentBaseType[],
  date: string
) =>
  assignmentsData
    ? assignmentsData.filter(
        (assignment: AssignmentBaseType) => assignment.edate === date
      )
    : []

const getDayFlags = (flags: ScheduleDateType[], day: Date) =>
  flags?.filter((flag: ScheduleDateType) => {
    const flagDate = parseISO(flag.link_date.link_date)
    return isSameDay(flagDate, day)
  }) || []

const getDailyOffs = (dayoffs: Dayoff[], day: Date) => {
  let typedDayoffs: Record<string, Dayoff[]> = {}

  dayoffs.forEach((dayoff: Dayoff) => {
    const dayoffDate = parseISO(dayoff.date)
    if (isSameDay(dayoffDate, day)) {
      if (dayoff.type_abbrev in typedDayoffs) {
        let tmp = typedDayoffs[dayoff.type_abbrev]
        typedDayoffs[dayoff.type_abbrev] = [...tmp, dayoff]
      } else {
        typedDayoffs[dayoff.type_abbrev] = [dayoff]
      }
    }
  })

  return typedDayoffs
}

const getDailyVacations = (vacations: Vacation[], day: Date) =>
  vacations?.filter((vacation: Vacation) => {
    const vacationDate = parseISO(vacation.link_date.link_date)
    return isSameDay(vacationDate, day)
  }) || []

const getDailyRequests = (requests: IRequest[], day: Date) =>
  requests?.filter((request: IRequest) => {
    const requestStartDate = parseISO(request.start_date)
    const requestEndDate = parseISO(request.end_date)
    return day >= requestStartDate && day <= requestEndDate
  }) || []

const Calendar = ({
  view,
  selectedDate,
  periodCount,
  assignments,
  jobsFilteredAssignments,
  flags,
  dayoffs,
  vacations,
  notes,
  requests,
  providers,
  jobs,
  filteredJobs,
  orderedHolidays,
  refreshAssignments,
  refreshFlags,
}: CalendarProps) => {
  const [startDate, setStartDate] = useState(parseISO(selectedDate))
  const calendarContainerRef = useRef<HTMLDivElement>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalDateOpen, setIsModalDateOpen] = useState(false)
  const [selectedAssignment, setSelectedAssignment] =
    useState<AssignmentBaseType>()
  const [formatDate, setFormatDate] = useState("")
  const [currentDate, setCurrentDate] = useState("")
  const [selectedRequestid, setSelectedRequestid] = useState<number | null>()
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)
  const [selectedCurrentDate, setSelectedCurrentDate] = useState("")

  const handleNotesModalOpen = (edate: string) => {
    setIsNotesModalOpen(true)
    const parsedDate = format(parseISO(edate), "EEEE, MM/dd/yy")
    setSelectedCurrentDate(parsedDate)
  }

  const handleNotesModalClose = () => {
    setIsNotesModalOpen(false)
    setSelectedCurrentDate("")
  }

  const {
    calendarConfig: {
      isMissingAssignmentsFilterActive,
      filterOptions: { providersFilters },
    },
  } = useSelector((state) => state.calendarEvents)

  useEffect(() => {
    const date = parseISO(selectedDate)
    setStartDate(date)
  }, [selectedDate, assignments])

  const showModal = (data: AssignmentBaseType) => {
    setIsModalOpen(true)
    setSelectedAssignment(cloneDeep(data))
  }

  const showModalToNewAssignments = (edate: string, jobid: number) => {
    setSelectedAssignment({ jobid, edate } as AssignmentBaseType)
    setIsModalOpen(true)
  }

  const hideModal = () => {
    setSelectedAssignment(undefined)
    setIsModalOpen(false)
    refreshAssignments()
  }

  const showDateModal = (date: string) => {
    setCurrentDate(date)
    setFormatDate(date)
    setIsModalDateOpen(true)
  }

  const jobsData =
    currentDate &&
    formatDate &&
    transformsDataMultipleAssigmentJobs(
      getDayAssignments(jobsFilteredAssignments, currentDate),
      flags,
      filteredJobs,
      formatDate
    )

  const hideDateModal = () => {
    refreshFlags()
    setIsModalDateOpen(false)
  }

  const renderHeader = () => {
    if (view === "Day") {
      return null
    }
    const startDayIndex = startDate?.getDay()
    return (
      <tr>
        {[...Array(7).keys()].map((i) => {
          const dayIndex = (startDayIndex + i) % 7
          return (
            <th
              className={cx(css.headWeekday, {
                [css.headWeekend]:
                  DaysOfWeek[dayIndex].includes("Saturday") ||
                  DaysOfWeek[dayIndex].includes("Sunday"),
              })}
              key={i}
            >
              {DaysOfWeek[dayIndex]}
            </th>
          )
        })}
      </tr>
    )
  }

  const renderDays = () => {
    const endDate = new Date(startDate.getTime())
    if (view === "Day") endDate.setDate(startDate.getDate() + periodCount)
    else if (view === "Week")
      endDate.setDate(startDate.getDate() + 7 * periodCount)
    else if (view === "Month")
      endDate.setMonth(startDate.getMonth() + periodCount)

    if (view === "Day" && periodCount >= 1) {
      const daysElements: JSX.Element[] = []
      let day = new Date(startDate)
      for (let i = 0; i < periodCount; i++) {
        const header = (
          <tr key={`header-${day.toISOString()}`}>
            <th
              className={cx(css.headDay, {
                [css.headWeekend]:
                  DaysOfWeek[day.getDay()].includes("Saturday") ||
                  DaysOfWeek[day.getDay()].includes("Sunday"),
              })}
              colSpan={7}
            >
              {format(day, "EEEE, d MMMM")}
            </th>
          </tr>
        )
        daysElements.push(header)

        const dayIndex = format(day, "yyyy-MM-dd")
        const dayAssignments = getDayAssignments(assignments, dayIndex)
        const groupedDayAssignments = getGroupedDayAssignments(assignments, day)
        const dayFlags = getDayFlags(flags, day)
        const dailyDayoffs = getDailyOffs(dayoffs, day)
        const dailyRequests = getDailyRequests(requests, day)
        const dailyVacations = getDailyVacations(vacations, day)
        const dailyNotes = getDailyNotes(notes, day)
        const holidaysByDate = getHolidaysOffDate(dayIndex, orderedHolidays)
        const unassignedJobs =
          providersFilters.providerIds &&
          providersFilters.providerIds?.length > 0
            ? []
            : filterUnassignedJobs(jobs, dayAssignments)

        let assignmentDetails: JSX.Element[] = []
        let flagDetails: JSX.Element[] = []
        let dayoffDetails: JSX.Element[] = []
        let requestDetails: JSX.Element[] = []
        let vacationDetails: JSX.Element[] = []
        let unassignedDetails: JSX.Element[] = []
        let noteDetails: JSX.Element[] = []

        dayFlags.forEach((flag: ScheduleDateType, index: number) => {
          flagDetails.push(
            <div key={index}>
              <span
                onClick={() =>
                  showModalToNewAssignments(
                    flag.link_date.link_date,
                    flag.job.jobid
                  )
                }
                className={css.jobAbbrev}
              >
                {flag.job.abbrev}
              </span>
              <Flag style={{ color: "red" }} className={css.flagIcon} />
            </div>
          )
        })

        groupedDayAssignments.forEach((assignment: AssignmentBaseType) => {
          const splitShiftKey = assignment.split_shift
            ? `-${assignment.split_shift?.starttime}-${assignment.split_shift?.endtime}`
            : ""

          assignmentDetails.push(
            <AssignmentElement
              assignment={assignment}
              showModal={showModal}
              key={
                assignment.eventid
                  ? `event-${assignment.eventid}-${assignment.job.jobid}${splitShiftKey}`
                  : `draft-${assignment.draft_eventid}-${assignment.job.jobid}${splitShiftKey}`
              }
            />
          )
        })

        for (const [typeAbbrev, dayoffs] of Object.entries(dailyDayoffs)) {
          dayoffDetails.push(
            <DayOffElement
              dayOffProviderId={
                dayoffs.map((dayOff) => dayOff.provider.providerid)?.[0]
              }
              dayOffTypeName={typeAbbrev}
              dayoffProviders={dayoffs
                .map((dayoff: Dayoff) => dayoff.provider.display_name)
                .join(", ")}
              key={`${typeAbbrev}-approvedDayoff`}
            />
          )
        }

        if (dailyVacations.length > 0) {
          vacationDetails.push(
            <VacationElement
              vacationProviderId={
                dailyVacations.map(
                  (vacation) => vacation.provider.providerid
                )?.[0]
              }
              vacationTypeName="v"
              vacationProviders={dailyVacations
                .map((vacation: Vacation) => vacation.provider.display_name)
                .join(", ")}
              key={`${day}-approvedVacation`}
            />
          )
        }

        if (dailyNotes.length > 0) {
          dailyNotes.forEach((dailyNote) => {
            if (dailyNote.show_on_calendar) {
              noteDetails.push(
                <NoteElement
                  key={`${dailyNote.noteid}-${dailyNote.userid}`}
                  content={dailyNote.note}
                />
              )
            }
          })
        }

        dailyRequests.forEach((request: IRequestType) => {
          requestDetails.push(
            <RequestElement
              request={request}
              setSelectedRequestid={setSelectedRequestid}
              key={`${request.requestid}-pendingRequest`}
            />
          )
        })

        unassignedJobs.forEach((job: JobUnassigned) => {
          unassignedDetails.push(
            <UnassignedElement
              assignment={job}
              key={job.jobid}
              onClick={showModalToNewAssignments}
              date={dayIndex}
            />
          )
        })

        const renderDetails = () => {
          if (isMissingAssignmentsFilterActive) {
            return <>{unassignedDetails}</>
          }
          return (
            <>
              {assignmentDetails}
              {assignmentDetails.length === 0 && flagDetails.length === 0 && (
                <p className={css.providerName}>
                  <span>No Assignments</span>
                </p>
              )}
              {unassignedDetails}
              {flagDetails}
              {dayoffDetails}
              {vacationDetails}
              {requestDetails}
              {noteDetails}
            </>
          )
        }
        const isToday = isSameDay(new Date(day), new Date())

        const dayContent = (
          <tr key={day.toISOString()}>
            <td
              className={cx(css.backgroundDay, {
                [css.todayOutlined]: isToday,
              })}
            >
              <div className="day-container">
                <div className="row">
                  <div className="col-xs-6 day-expand">
                    <div className={css.currentDate}>
                      <p>{`${day.getDate()}`}</p>
                      <div className={css.holidaysContainer}>
                        {holidaysByDate.map((holiday) => (
                          <div key={holiday.holidayId}>{holiday.name}</div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className={`col-xs-6 day-of-month ${css.addContainer}`}>
                    <a
                      onClick={() => showDateModal(dayIndex)}
                      className="hover-fade"
                    >
                      <AddIcon className={css.addIconColor} />
                    </a>
                  </div>
                </div>
                <div className="month-details">
                  <div>
                    <div className="row">
                      <div className="col-xs-12 month-detail-events">
                        <div className={css.assignmentsWrapper}>
                          {renderDetails()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        )
        daysElements.push(dayContent)

        day = new Date(day.setDate(day.getDate() + 1))
      }

      return <>{daysElements.map((element) => element)}</>
    } else {
      const daysElements: JSX.Element[][] = []
      let day = new Date(startDate)

      while (day < endDate || countElements(daysElements) % 7 !== 0) {
        const isWithinPeriod = day >= new Date(selectedDate) && day < endDate
        const monthAbbrev = MonthsAbbrev[day.getMonth()]

        const dayIndex = format(day, "yyyy-MM-dd")
        const dayAssignments = getDayAssignments(assignments, dayIndex)
        const groupedDayAssignments = getGroupedDayAssignments(assignments, day)
        const dayFlags = getDayFlags(flags, day)
        const dailyDayoffs = getDailyOffs(dayoffs, day)
        const dailyRequests = getDailyRequests(requests, day)
        const dailyVacations = getDailyVacations(vacations, day)
        const dailyNotes = getDailyNotes(notes, day)
        const holidaysByDate = getHolidaysOffDate(dayIndex, orderedHolidays)
        const unassignedJobs =
          providersFilters.providerIds &&
          providersFilters.providerIds?.length > 0
            ? []
            : filterUnassignedJobs(jobs, dayAssignments)

        let assignmentDetails: JSX.Element[] = []
        let flagDetails: JSX.Element[] = []
        let dayoffDetails: JSX.Element[] = []
        let requestDetails: JSX.Element[] = []
        let vacationDetails: JSX.Element[] = []
        let unassignedDetails: JSX.Element[] = []
        let noteDetails: JSX.Element[] = []

        dayFlags.forEach((flag: ScheduleDateType, index: number) => {
          flagDetails.push(
            <div key={index}>
              <span
                onClick={() =>
                  showModalToNewAssignments(
                    flag.link_date.link_date,
                    flag.job.jobid
                  )
                }
                className={css.jobAbbrev}
              >
                {flag.job.abbrev}
              </span>
              <Flag style={{ color: "red" }} className={css.flagIcon} />
            </div>
          )
        })

        groupedDayAssignments.forEach((assignment: AssignmentBaseType) => {
          const splitShiftKey = assignment.split_shift
            ? `-${assignment.split_shift?.starttime}-${assignment.split_shift?.endtime}`
            : ""

          assignmentDetails.push(
            <AssignmentElement
              assignment={assignment}
              showModal={showModal}
              key={
                assignment.eventid
                  ? `event-${assignment.eventid}-${assignment.job?.jobid}${splitShiftKey}`
                  : `draft-${assignment.draft_eventid}-${assignment.job?.jobid}${splitShiftKey}`
              }
            />
          )
        })

        for (const [typeAbbrev, dayoffs] of Object.entries(dailyDayoffs)) {
          dayoffDetails.push(
            <DayOffElement
              dayOffProviderId={
                dayoffs.map((dayOff) => dayOff.provider.providerid)?.[0]
              }
              dayOffTypeName={typeAbbrev}
              dayoffProviders={dayoffs
                .map((dayoff: Dayoff) => dayoff.provider.display_name)
                .join(", ")}
              key={`${typeAbbrev}-approvedDayoff`}
            />
          )
        }

        if (dailyVacations.length > 0) {
          vacationDetails.push(
            <VacationElement
              vacationProviderId={
                dailyVacations.map(
                  (vacation) => vacation.provider.providerid
                )?.[0]
              }
              vacationTypeName="v"
              vacationProviders={dailyVacations
                .map((vacation: Vacation) => vacation.provider.display_name)
                .join(", ")}
              key={`${day}-approvedVacation`}
            />
          )
        }
        if (dailyNotes.length > 0) {
          dailyNotes.forEach((dailyNote) => {
            if (dailyNote.show_on_calendar) {
              noteDetails.push(
                <NoteElement
                  key={`${dailyNote.noteid}-${dailyNote.userid}`}
                  content={dailyNote.note}
                />
              )
            }
          })
        }

        dailyRequests.forEach((request: IRequest) => {
          requestDetails.push(
            <RequestElement
              request={request}
              setSelectedRequestid={setSelectedRequestid}
              key={`${request.requestid}-pendingRequest-${request.jobid}-${request.requestorid}`}
            />
          )
        })

        unassignedJobs.forEach((job: JobUnassigned) => {
          unassignedDetails.push(
            <UnassignedElement
              assignment={job}
              key={job.jobid}
              onClick={showModalToNewAssignments}
              date={dayIndex}
            />
          )
        })

        const renderDetails = () => {
          if (isMissingAssignmentsFilterActive) {
            return <>{unassignedDetails}</>
          }
          return (
            <>
              {assignmentDetails}
              {assignmentDetails.length === 0 && flagDetails.length === 0 && (
                <p className={css.providerName}>
                  <span>No Assignments</span>
                </p>
              )}
              {unassignedDetails}
              {flagDetails}
              {dayoffDetails}
              {vacationDetails}
              {requestDetails}
              {noteDetails}
            </>
          )
        }

        const isToday = isSameDay(new Date(day), new Date())

        const dayElement = (
          <td
            key={day.toISOString()}
            className={cx(
              isWithinPeriod ? css.backgroundDay : css.otherMonthDay,
              { [css.todayOutlined]: isToday }
            )}
          >
            <div className="day-container">
              <div>
                <div className={css.dayGridHeaderWrapper}>
                  <div className={css.dayGridHeaderContent}>
                    <p
                      className={css.dayGridHeaderDate}
                    >{`${monthAbbrev} ${day.getDate()}`}</p>
                    <div className={css.dayGridHeaderHolidaysWrapper}>
                      {holidaysByDate.map((holidayByDate) => (
                        <div key={holidayByDate.holidayId}>
                          {holidayByDate.name}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className={css.dayGridHeaderRightContainer}>
                    {dailyNotes.length > 0 ? (
                      <button
                        onClick={() => handleNotesModalOpen(dayIndex)}
                        className={css.noteButton}
                      >
                        Notes
                      </button>
                    ) : null}

                    <div className={css.dayGridHeaderAddContainer}>
                      <a
                        onClick={() => showDateModal(dayIndex)}
                        className="hover-fade"
                      >
                        <AddIcon className={css.addIconColor} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="month-details">
                <div>
                  <div className="row">
                    <div className="col-xs-12 month-detail-events">
                      <div className={css.assignmentsWrapper}>
                        {renderDetails()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        )

        if (countElements(daysElements) % 7 === 0) daysElements.push([])
        daysElements[daysElements.length - 1].push(dayElement)
        day = new Date(day.setDate(day.getDate() + 1))
      }

      return daysElements.map((week, index) => (
        <tr key={`week-${index}`}>{week}</tr>
      ))
    }
  }

  const countElements = (array: JSX.Element[][]): number => {
    return array.reduce((acc, val) => acc + val.length, 0)
  }

  return (
    <div className={css.calendarContainer} ref={calendarContainerRef}>
      <div className={css.headerContainer}>
        <table className="table calendar-month">
          <thead>{renderHeader()}</thead>
        </table>
      </div>

      <div className={css.daysContainer}>
        <table className={`table calendar-month ${css.tableContainer}`}>
          <tbody>{renderDays()}</tbody>
        </table>
      </div>
      {selectedAssignment && (
        <AssignmentContentModal
          show={isModalOpen}
          hideModal={hideModal}
          jobid={selectedAssignment.jobid}
          edate={selectedAssignment.edate}
        />
      )}
      {selectedRequestid && (
        <RequestModal
          requestid={selectedRequestid}
          onHide={() => {
            setSelectedRequestid(null)
            hideModal()
          }}
        />
      )}
      <JobsTableModal
        centered={true}
        isModalDateOpen={isModalDateOpen}
        onHide={hideDateModal}
        date={formatDate}
        currentDate={currentDate}
        onCancel={hideDateModal}
        onSave={hideDateModal}
        closeButton={true}
        size="xl"
        jobsData={jobsData as unknown as MultipleAssigmentJob[]}
      />
      <NotesModal
        date={selectedCurrentDate}
        isModalOpen={isNotesModalOpen}
        onCloseModal={handleNotesModalClose}
        closeButton
      />
    </div>
  )
}

export default Calendar
