import React, { useCallback, useMemo } from "react"
import cx from "classnames"
import css from "./AssignmentSingleJobElement.module.scss"
import { GroupedAssignment } from "@app/services/getGroupedDayAssignments"
import { useSelector } from "@app/models"
import { AssignmentDetails } from "../AssignmentDetails"
import { getIsSingleAssignmentHighlighted } from "../utils/getIsSingleAssignmentHighlighted"
import { getIsAssignmentFiltered } from "../utils/getIsAssignmentFiltered"
import CustomTooltip from "../Tooltip"

interface Props {
  assignment: GroupedAssignment
  showModal: () => void
  isJobSelectedInQuickAssignMode: (providerId: number) => boolean
  isAssignmentUnderstaffed: boolean | undefined
  getAssignmentUnderstaffedText: () => string
  quickMode?: boolean
  handleDeleteQuickAssign?: (assignment: GroupedAssignment) => void
}

export const AssignmentSingleJobElement = ({
  assignment,
  showModal,
  isJobSelectedInQuickAssignMode,
  quickMode,
  handleDeleteQuickAssign,
  isAssignmentUnderstaffed,
  getAssignmentUnderstaffedText,
}: Props) => {
  const {
    highlightOptions: { highlightProviders },
    filterOptions: {
      providersFilters: { providerIds },
    },
    isUnderstaffedJobsHighlightActive,
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  const providersAreHighlighted = useMemo(
    () => highlightProviders && highlightProviders.length > 0,
    [highlightProviders]
  )

  const isAssignmentHighlighted = getIsSingleAssignmentHighlighted(
    assignment,
    highlightProviders
  )

  const isProviderFilterActive = useMemo(
    () => providerIds && providerIds?.length > 0,
    [providerIds]
  )

  const isAssignmentFiltered = getIsAssignmentFiltered(
    assignment.provider?.providerid,
    providerIds
  )

  const assignmentClasses = cx(css.assignmentSingleElementWrapper, {
    [css.disabledCellJob]: !isJobSelectedInQuickAssignMode(
      assignment.provider.providerid
    ),
    [css.highlightedAssignment]: isAssignmentHighlighted,
    [css.highlightedAssignmentDraft]:
      isAssignmentHighlighted && assignment?.draft_eventid,
    [css.fadedAssignmentWrapper]:
      (providersAreHighlighted || isUnderstaffedJobsHighlightActive) &&
      !isAssignmentHighlighted,
    [css.fadedDraftAssignmentWrapper]:
      (providersAreHighlighted || isUnderstaffedJobsHighlightActive) &&
      !isAssignmentHighlighted &&
      assignment.draft_eventid,
  })

  const handleClick = useCallback(() => {
    if (quickMode) {
      handleDeleteQuickAssign?.(assignment)
    } else {
      showModal()
    }
  }, [quickMode, assignment])

  return (
    <button
      className={assignmentClasses}
      onClick={handleClick}
      disabled={!isJobSelectedInQuickAssignMode(assignment.provider.providerid)}
    >
      {isProviderFilterActive && isAssignmentFiltered ? (
        <CustomTooltip
          arrow
          title={
            <div className={css.textContainer}>
              <AssignmentDetails
                isDraft={Boolean(assignment.draft_eventid)}
                providerColor={assignment.provider?.provider_color}
                providerName={assignment.provider?.display_name}
              />
            </div>
          }
        >
          <span className={css.filteredText}>Filtered</span>
        </CustomTooltip>
      ) : (
        <div className={css.textContainer}>
          <AssignmentDetails
            isDraft={Boolean(assignment.draft_eventid)}
            providerColor={assignment.provider?.provider_color}
            providerName={assignment.provider?.display_name}
          />
        </div>
      )}

      {isUnderstaffedJobsHighlightActive && isAssignmentUnderstaffed && (
        <div className={css.understaffedCounter}>
          {getAssignmentUnderstaffedText()}
        </div>
      )}
    </button>
  )
}
