import React from "react"
import css from "./RequestProviderElement.module.scss"
import cx from "classnames"
import { useSelector } from "@app/models"

interface RequestProviderElementProps {
  request: IRequestType
  setSelectedRequestid: () => void
  isCardDisabled: boolean
}

export const RequestProviderElement = ({
  request,
  setSelectedRequestid,
  isCardDisabled,
}: RequestProviderElementProps) => {
  const { showJobTimesInCalendar } = useSelector(
    (state) => state.calendarEvents.calendarConfig
  )

  return (
    <button
      className={cx(css.requestWrapper, {
        [css.disabledCellJob]: isCardDisabled,
        [css.smallCards]: !showJobTimesInCalendar,
      })}
      onClick={setSelectedRequestid}
    >
      {request.request_type} ({request.request_status})
    </button>
  )
}
