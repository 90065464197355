import React, { useMemo } from "react"
import { useSelector } from "@app/models"
import css from "./DayOffElement.module.scss"
import cx from "classnames"

interface DayOffElementProps {
  dayoffProviders: string
  dayOffTypeName: string
  dayOffProviderId: number
}

export const DayOffElement = ({
  dayoffProviders,
  dayOffTypeName,
  dayOffProviderId,
}: DayOffElementProps) => {
  const {
    highlightOptions: { highlightProviders },
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  const providersAreHighlighted = useMemo(
    () => highlightProviders && highlightProviders.length > 0,
    [highlightProviders]
  )

  const isDayOffHighlighted = useMemo(
    () =>
      highlightProviders?.some(
        (highlightedProvider) =>
          dayOffProviderId === highlightedProvider.providerid
      ),
    [highlightProviders]
  )

  return (
    <div
      className={cx(css.dayoffWrapper, {
        [css.highlightedDayOff]: providersAreHighlighted && isDayOffHighlighted,
        [css.fadedDayOff]: providersAreHighlighted && !isDayOffHighlighted,
      })}
    >{`${dayoffProviders} - ${dayOffTypeName}`}</div>
  )
}
