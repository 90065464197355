import { createModel } from "@rematch/core"
import { RootModel } from "."
import { ViolationQuickAssignment } from "@app/containers/spa/WhiteboardCalendar/data"

type QuickAssignmentsState = {
  quickAssignmentViolations: ViolationQuickAssignment[]
  quickAssignments: ViolationQuickAssignment[]
}

export default createModel<RootModel>()({
  state: {
    quickAssignmentViolations: [],
    quickAssignments: [],
  } as QuickAssignmentsState,
  reducers: {
    addQuickAssignment(state, payload: ViolationQuickAssignment) {
      state.quickAssignments.push(payload)
    },
    setQuickAssignmentViolations(state, payload: ViolationQuickAssignment[]) {
      state.quickAssignmentViolations = payload
    },
    removeQuickAssignmentViolation(state, eventid: number | undefined) {
      state.quickAssignmentViolations = state.quickAssignmentViolations.filter(
        (violation) =>
          violation.eventid !== eventid && violation.draft_eventid !== eventid
      )
    },
    removeQuickAssignment(state, eventid: number | undefined) {
      state.quickAssignments = state.quickAssignments.filter(
        (assignment) =>
          assignment.eventid !== eventid && assignment.draft_eventid !== eventid
      )
    },
    removeQuickAssignmentAndViolation(state, eventid: number | undefined) {
      state.quickAssignments = state.quickAssignments.filter(
        (assignment) =>
          assignment.eventid !== eventid && assignment.draft_eventid !== eventid
      )
      state.quickAssignmentViolations = state.quickAssignmentViolations.filter(
        (violation) =>
          violation.eventid !== eventid && violation.draft_eventid !== eventid
      )
    },
    resetQuickAssignments(state) {
      state.quickAssignmentViolations = []
      state.quickAssignments = []
    },
  },
  effects: (dispatch) => ({
    async updateQuickAssignments(payload: {
      assignmentCount: number
      quickAssignmentViolations: ViolationQuickAssignment[]
    }) {
      dispatch.quickAssignments.setQuickAssignmentViolations(
        payload.quickAssignmentViolations
      )
    },
  }),
})
