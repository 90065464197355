import React, { useMemo, useState } from "react"
import css from "./FilterDaysOff.module.scss"
import { H5 } from "@app/components/Typography"
import { useDispatch, useSelector } from "@app/models"
import CheckIcon from "@material-ui/icons/Check"
import {
  IRequestStatus,
  IRequestType,
} from "@app/containers/spa/WhiteboardCalendar/data"

interface DaysOffFiltersProps {
  requestStatus: IRequestStatus[]
  requestTypes: IRequestType[]
  onClose: () => void
}

export const FilterDaysOff = ({
  requestStatus,
  requestTypes,
  onClose,
}: DaysOffFiltersProps) => {
  const { calendarConfig } = useSelector((state) => state.calendarEvents)

  const dayoffFilters = calendarConfig.filterOptions.dayoffFilters || {
    activeSubFilter: [],
    dayoffIds: [],
    requestStatus: [],
    requestStatusIds: requestStatus.map((status) => status.request_statusid),
    requestTypeIds: requestTypes.map(
      (requestType) => requestType.request_typeid
    ),
  }

  const [selectedRequestStatusIds, setSelectedRequestStatusIds] = useState(
    dayoffFilters.requestStatusIds || []
  )
  const [selectedRequestTypeIds, setSelectedRequestTypeIds] = useState(
    dayoffFilters.requestTypeIds || []
  )

  const showVacations = () => {
    const vacationType = requestTypes.find((rt) => rt.type === "Vacation")
    return Boolean(
      vacationType &&
        selectedRequestTypeIds.includes(vacationType.request_typeid)
    )
  }

  const dispatch = useDispatch()

  const handleSelectedRequestStatus = (status: IRequestStatus) => {
    const isSelected = selectedRequestStatusIds.includes(
      status.request_statusid
    )
    if (!isSelected) {
      setSelectedRequestStatusIds([
        ...selectedRequestStatusIds,
        status.request_statusid,
      ])
    } else {
      setSelectedRequestStatusIds(
        selectedRequestStatusIds.filter(
          (requestStatusId) => requestStatusId !== status.request_statusid
        )
      )
    }
  }

  const handleSelectedRequestTypes = (requestType: IRequestType) => {
    const isSelected = selectedRequestTypeIds.includes(
      requestType.request_typeid
    )
    if (!isSelected) {
      setSelectedRequestTypeIds([
        ...selectedRequestTypeIds,
        requestType.request_typeid,
      ])
    } else {
      setSelectedRequestTypeIds(
        selectedRequestTypeIds.filter(
          (requestTypeId) => requestTypeId !== requestType.request_typeid
        )
      )
    }
  }

  const handleApplyFilters = () => {
    dispatch.calendarEvents.setFilterOptions({
      dayoffFilters: {
        activeSubFilter: dayoffFilters.activeSubFilter,
        requestStatusIds: selectedRequestStatusIds,
        requestTypeIds: selectedRequestTypeIds,
        showVacations: showVacations(),
      },
    })
    onClose()
  }

  const handleCancel = () => {
    setSelectedRequestStatusIds(dayoffFilters.requestStatusIds || [])
    setSelectedRequestTypeIds(dayoffFilters.requestTypeIds || [])
    onClose()
  }

  const handleSelectAllDayoffs = () => {
    const allFilteredDayoffsSelected =
      requestTypes.length === selectedRequestTypeIds.length

    if (!allFilteredDayoffsSelected) {
      setSelectedRequestTypeIds(
        requestTypes.map((requestType) => requestType.request_typeid)
      )
    } else {
      setSelectedRequestTypeIds([])
    }
  }

  const handleSelectAllRequestStatus = () => {
    const allRequestStatusSelected =
      requestStatus.length === selectedRequestStatusIds?.length

    if (!allRequestStatusSelected) {
      setSelectedRequestStatusIds(
        requestStatus.map((requestStatus) => requestStatus.request_statusid)
      )
    } else {
      setSelectedRequestStatusIds([])
    }
  }

  const selectAllDisplayedText = useMemo(() => {
    const allFilteredDayoffsSelected =
      requestTypes.length === selectedRequestTypeIds.length

    return allFilteredDayoffsSelected ? "Unselect All" : "Select All"
  }, [requestTypes, selectedRequestTypeIds])

  const selectAllRequestStatusText = useMemo(() => {
    const allRequestStatusSelected =
      requestStatus.length === selectedRequestStatusIds.length

    return allRequestStatusSelected ? "Unselect All" : "Select All"
  }, [requestStatus, selectedRequestStatusIds])

  const requestTypeLabel = (requestType: IRequestType) => {
    return requestType.dayoff_type
      ? `${requestType.dayoff_type.abbrev} - ${requestType.dayoff_type.type}`
      : requestType.type
  }

  return (
    <div className={css.jobContainer}>
      <div className={css.innerContainer}>
        <div className={css.selectAllContainer}>
          <div
            className={css.selectButton}
            onClick={handleSelectAllRequestStatus}
          >
            {selectAllRequestStatusText}
          </div>
        </div>

        <div className={css.customSelect}>
          <ul>
            {requestStatus.length === 0 && <div>No request status found</div>}
            {requestStatus.map((status) => (
              <li
                key={status.request_statusid}
                className={css.customOption}
                onClick={() => handleSelectedRequestStatus(status)}
              >
                <div className={css.optionInnerContainer}>
                  <H5 ellipsis>{status.status}</H5>
                  <div
                    className={`${css.checkboxContainer} ${
                      selectedRequestStatusIds.includes(status.request_statusid)
                        ? css.checked
                        : css.unchecked
                    }`}
                  >
                    {selectedRequestStatusIds.includes(
                      status.request_statusid
                    ) ? (
                      <CheckIcon className={css.checkIcon} />
                    ) : (
                      <div className={css.uncheckedIcon}></div>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className={css.selectAllContainer}>
          <div className={css.selectButton} onClick={handleSelectAllDayoffs}>
            {selectAllDisplayedText}
          </div>
        </div>

        <div className={css.customSelect}>
          <ul>
            {requestTypes.length === 0 && <div>No dayoffs found</div>}
            {requestTypes.map((requestType) => (
              <li
                key={requestType.request_typeid}
                className={css.customOption}
                onClick={() => handleSelectedRequestTypes(requestType)}
              >
                <div className={css.optionInnerContainer}>
                  <H5 ellipsis>{requestTypeLabel(requestType)}</H5>
                  <div
                    className={`${css.checkboxContainer} ${
                      selectedRequestTypeIds.includes(
                        requestType.request_typeid
                      )
                        ? css.checked
                        : css.unchecked
                    }`}
                  >
                    {selectedRequestTypeIds.includes(
                      requestType.request_typeid
                    ) ? (
                      <CheckIcon className={css.checkIcon} />
                    ) : (
                      <div className={css.uncheckedIcon}></div>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className={css.buttonContainer}>
        <button className={css.cancelButton} onClick={handleCancel}>
          Cancel
        </button>
        <button className={css.applyButton} onClick={handleApplyFilters}>
          Apply
        </button>
      </div>
    </div>
  )
}
