import { debounce } from "lodash"
import { handleApiError } from "@app/utils"
import { ProvidersRules } from "@app/utils/constants"
import {
  Provider as CalendarProvider,
  ViolationQuickAssignment,
} from "@app/containers/spa/WhiteboardCalendar/data"
import api from "./api"
import { format, parseISO } from "date-fns"

type RuleApplied = {
  rule: string
  message: string
  data: any[]
}

const calculateQuickAssigmentViolationsDebounced = debounce(
  async (
    filteredQuickAssignments: AssignmentBaseType[],
    selectedQuickProvider: CalendarProvider | undefined,
    updateQuickAssignments: (
      assignmentCount: number,
      violations: ViolationQuickAssignment[]
    ) => void
  ) => {
    if (!selectedQuickProvider) {
      updateQuickAssignments(0, [])
      return
    }

    const jobDatePairs = filteredQuickAssignments.map((assignment) => ({
      jobid: Number(assignment.jobid),
      date: format(parseISO(assignment.edate), "yyyy-MM-dd"),
    }))

    try {
      const response = await Promise.all(
        jobDatePairs.map((pair) =>
          api.getProvidersV3({
            jobid: pair.jobid,
            date: pair.date,
            rules: ProvidersRules,
          })
        )
      )

      const jobDetailsPromises = filteredQuickAssignments.map((assignment) =>
        api.getJob(Number(assignment.jobid))
      )
      const jobDetails = await Promise.all(jobDetailsPromises)

      const violations = filteredQuickAssignments.reduce(
        (acc, assignment, index) => {
          const providerRules = response[index] ?? []
          const matchingProvider = providerRules.find(
            (provider: any) =>
              provider.providerid === selectedQuickProvider.providerid
          )

          if (
            matchingProvider &&
            Array.isArray(matchingProvider.rulesApplied) &&
            matchingProvider.rulesApplied.length > 0 &&
            matchingProvider.rulesApplied.some(
              (rule: RuleApplied) => Object.keys(rule).length > 0
            )
          ) {
            const jobDetail = jobDetails[index]
            acc.push({
              ...assignment,
              rulesApplied: matchingProvider.rulesApplied,
              job: jobDetail,
            })
          }

          return acc
        },
        [] as ViolationQuickAssignment[]
      )

      updateQuickAssignments(filteredQuickAssignments.length, violations)
    } catch (error) {
      handleApiError
      updateQuickAssignments(0, [])
    }
  },
  300
)

export const calculateQuickAssigmentViolations =
  calculateQuickAssigmentViolationsDebounced

const calculateQuickAssigmentJobViolationsDebounced = debounce(
  async (
    filteredQuickAssignments: AssignmentBaseType[],
    providersData: CalendarProvider[],
    updateQuickAssignments: (
      assignmentCount: number,
      violations: ViolationQuickAssignment[]
    ) => void
  ) => {
    if (!filteredQuickAssignments) {
      updateQuickAssignments(0, [])
      return
    }

    const jobDatePairs = filteredQuickAssignments.map((assignment) => ({
      jobid: Number(assignment.jobid),
      date: format(parseISO(assignment.edate), "yyyy-MM-dd"),
    }))

    try {
      const response = await Promise.all(
        jobDatePairs.map((pair) =>
          api.getProvidersV3({
            jobid: pair.jobid,
            date: pair.date,
            rules: ProvidersRules,
          })
        )
      )

      const violations = filteredQuickAssignments.reduce(
        (acc, assignment, index) => {
          const providerRules = response[index] ?? []
          const matchingProvider = providerRules.find(
            (provider: any) => provider.providerid === assignment.providerid
          )

          if (
            matchingProvider &&
            Array.isArray(matchingProvider.rulesApplied) &&
            matchingProvider.rulesApplied.length > 0 &&
            matchingProvider.rulesApplied.some(
              (rule: RuleApplied) => Object.keys(rule).length > 0
            )
          ) {
            const providerData = providersData.find(
              (provider) => provider.providerid === assignment.providerid
            )

            acc.push({
              ...assignment,
              rulesApplied: matchingProvider.rulesApplied,
              provider: {
                ...providerData!,
                avatar: providerData!.avatar || "",
                providerid: Number(providerData!.providerid),
              },
            })
          }

          return acc
        },
        [] as ViolationQuickAssignment[]
      )

      updateQuickAssignments(filteredQuickAssignments.length, violations)
    } catch (error) {
      handleApiError
      updateQuickAssignments(0, [])
    }
  },
  300
)

export const calculateQuickAssigmentJobViolations =
  calculateQuickAssigmentJobViolationsDebounced
