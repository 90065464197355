import React, { useState, useMemo, useCallback, useEffect } from "react"
import css from "./JobsTable.module.scss"
import FilterHeader from "./components/JobsTable/FilterHeader"
import JobRow from "./components/JobsTable/JobRow"
import { JobsTableHeader } from "@app/utils/constants"
import {
  Provider,
  MultipleAssigmentJob,
  TransformedProvider,
} from "@app/containers/spa/WhiteboardCalendar/data"
import DraftPublish from "../DraftPublish"
import { orderJobsFunc } from "@app/utils/sorts"
import {
  transformJobsTableData,
  FormData,
} from "@app/containers/spa/WhiteboardCalendar/component/JobsTableModal/hooks/jobsTableUseForm"
import { Control, UseFormSetValue } from "react-hook-form"

interface Props {
  data: MultipleAssigmentJob[]
  providers: Provider[]
  date: string
  currentDate: string
  onShowAvailableProviders: (
    jobId: number,
    ruledProvidersMappings: Record<string, any>,
    jobName: string
  ) => void
  focusedJobId: number | null
  control: Control<FormData>
  setValue: UseFormSetValue<FormData>
  fields: (object & { id: string })[]
  setOnSplitCount: React.Dispatch<React.SetStateAction<number>>
  setAdditionalAssignmentsEnabled: (enabled: boolean) => void
}

interface JobItem {
  type: string
  status?: string
}

const headerKeyMap: { [key: string]: keyof MultipleAssigmentJob } = {
  Job: "job",
  Providers: "provider",
  "Shift Time": "shifttime",
  Splits: "splits",
  Max: "max",
  Type: "type",
  Flag: "flag",
  Status: "status",
}

const JobsTable: React.FC<Props> = ({
  data,
  providers,
  date,
  currentDate,
  onShowAvailableProviders,
  focusedJobId,
  control,
  setValue,
  fields,
  setOnSplitCount,
  setAdditionalAssignmentsEnabled,
}) => {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof MultipleAssigmentJob | null
    direction: "ascending" | "descending"
  }>({ key: null, direction: "ascending" })
  const [typeFilter, setTypeFilter] = useState("")
  const [statusFilter, setStatusFilter] = useState("")
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({})
  const [expandedSplitRows, setExpandedSplitRows] = useState<
    Record<number, boolean>
  >({})
  const [ruledProvidersMappings, setRuledProvidersMappings] = useState<
    Record<string, any>
  >({})
  const [draftToPublish, setDraftToPublish] = useState<
    MultipleAssigmentJob | undefined
  >()

  const uniqueTypes = useMemo(
    () => Array.from(new Set(data.map((item) => item.type))),
    [data]
  )

  const uniqueStatuses = useMemo(
    () =>
      Array.from(
        new Set(
          data.map((item) =>
            item.status && item.status.trim() !== "" ? item.status : "No Status"
          )
        )
      ),
    [data]
  )

  const requestSort = useCallback(
    (key: keyof MultipleAssigmentJob) => {
      let direction: "ascending" | "descending" = "ascending"
      if (sortConfig.key === key && sortConfig.direction === "ascending") {
        direction = "descending"
      }
      setSortConfig({ key, direction })
    },
    [sortConfig]
  )

  const availableItems = useMemo(() => {
    return data.filter((item) => item.availability).sort(orderJobsFunc)
  }, [data])

  const filteredItems = useMemo(() => {
    const isTypeMatch = (item: JobItem) =>
      !typeFilter || item.type === typeFilter
    const isStatusMatch = (item: JobItem) => {
      if (!statusFilter) return true
      if (statusFilter === "No Status")
        return !item.status || item.status.trim() === ""
      return item.status === statusFilter
    }
    return availableItems.filter(
      (item: JobItem) => isTypeMatch(item) && isStatusMatch(item)
    )
  }, [availableItems, typeFilter, statusFilter])

  const sortedItems = useMemo(() => {
    if (!sortConfig.key) return filteredItems

    return [...filteredItems].sort((a, b) => {
      if (a[sortConfig.key!] < b[sortConfig.key!])
        return sortConfig.direction === "ascending" ? -1 : 1
      if (a[sortConfig.key!] > b[sortConfig.key!])
        return sortConfig.direction === "ascending" ? 1 : -1
      return 0
    })
  }, [filteredItems, sortConfig])

  const defaultProviders = useMemo(
    () =>
      providers.map(
        (provider) =>
          ({
            ...provider,
            avatar: provider.avatar || undefined,
            providerid: provider.providerid,
          } as TransformedProvider)
      ),
    [providers]
  )

  useEffect(() => {
    const dynamicData = transformJobsTableData(sortedItems)
    setValue("formElements", dynamicData)
  }, [sortedItems])

  const toggleExpandedRow = useCallback((rowIndex: number) => {
    setExpandedRows((prev) => ({ ...prev, [rowIndex]: !prev[rowIndex] }))
  }, [])

  const toggleExpandedSplitRow = useCallback((rowIndex: number) => {
    setExpandedSplitRows((prev) => ({ ...prev, [rowIndex]: !prev[rowIndex] }))
  }, [])

  return (
    <div className={css.tableContainer}>
      <table className={css.jobsTable}>
        <thead>
          <tr>
            <FilterHeader
              requestSort={requestSort}
              headerKeyMap={headerKeyMap}
              sortConfig={sortConfig}
              uniqueTypes={uniqueTypes}
              uniqueStatuses={uniqueStatuses}
              typeFilter={typeFilter}
              setTypeFilter={setTypeFilter}
              statusFilter={statusFilter}
              setStatusFilter={setStatusFilter}
            />
          </tr>
        </thead>
        <tbody>
          {fields.length > 0 ? (
            fields.map((field, rowIndex) => (
              <JobRow
                key={field.id}
                name={`formElements.${rowIndex}`}
                control={control}
                item={sortedItems[rowIndex]}
                rowIndex={rowIndex}
                JobsTableHeader={JobsTableHeader}
                headerKeyMap={headerKeyMap}
                date={date}
                currentDate={currentDate}
                expandedRows={expandedRows}
                toggleExpandedRow={toggleExpandedRow}
                expandedSplitRows={expandedSplitRows}
                toggleExpandedSplitRow={toggleExpandedSplitRow}
                defaultProviders={defaultProviders}
                setRuledProvidersMappings={setRuledProvidersMappings}
                setSelectedJobId={(jobId) => {
                  if (jobId !== undefined && typeof jobId === "number") {
                    onShowAvailableProviders(
                      jobId,
                      ruledProvidersMappings,
                      sortedItems[rowIndex]?.job
                    )
                  }
                }}
                setDraftToPublish={setDraftToPublish}
                focusedJobId={focusedJobId}
                setOnSplitCount={setOnSplitCount}
                setAdditionalAssignmentsEnabled={
                  setAdditionalAssignmentsEnabled
                }
              />
            ))
          ) : (
            <tr>
              <td
                colSpan={JobsTableHeader.length + 1}
                style={{ textAlign: "center" }}
              >
                No information available
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {draftToPublish && (
        <DraftPublish
          item={draftToPublish}
          edate={date}
          showPublish={setDraftToPublish}
        />
      )}
    </div>
  )
}

export default JobsTable
