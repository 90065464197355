import React, { useCallback, useMemo } from "react"
import { useSelector } from "@app/models"
import { GroupedAssignment } from "@app/services/getGroupedDayAssignments"
import AssignmentSingleJobElement from "./AssignmentSingleJobElement"
import AssignmentMultipleJobsElement from "./AssignmentMultipleJobsElement"

interface AssignmentJobElementProps {
  assignment: GroupedAssignment
  showModal: () => void
  quickMode?: boolean
  handleDeleteQuickAssign?: (assignment: GroupedAssignment) => void
}

export const AssignmentJobElement = ({
  assignment,
  showModal,
  quickMode,
  handleDeleteQuickAssign,
}: AssignmentJobElementProps) => {
  const { selectedProvider } = useSelector(
    (state) => state.calendarEvents.calendarConfig
  )

  const { jobs } = useSelector((state) => state.groupData)

  const { isUnderstaffedJobsHighlightActive } = useSelector(
    (state) => state.calendarEvents.calendarConfig
  )

  const additionalEventAssignments = useMemo(
    () =>
      assignment?.eventid
        ? assignment.additional_event_assignments
        : assignment.draft_additional_event_assignments,
    [
      assignment?.eventid,
      assignment.additional_event_assignments,
      assignment.draft_additional_event_assignments,
    ]
  )

  const isMultiOrSplitAssignment = useMemo(
    () =>
      (additionalEventAssignments && additionalEventAssignments?.length > 0) ||
      (assignment?.additional_split_event_assignments &&
        assignment?.additional_split_event_assignments?.length > 0),
    [assignment?.additional_split_event_assignments, additionalEventAssignments]
  )

  const isJobSelectedInQuickAssignMode = (providerId: number) => {
    if (!selectedProvider) return true
    return providerId === selectedProvider?.providerid
  }

  const isAdditionalJobDisabled = useMemo(() => {
    return additionalEventAssignments?.some((eventAssignment) =>
      isJobSelectedInQuickAssignMode(eventAssignment.provider.providerid)
    )
  }, [additionalEventAssignments])

  const isSplitJobDisabled = useMemo(() => {
    if (!assignment.additional_split_event_assignments) return false
    return assignment.additional_split_event_assignments?.some(
      (splitEventAssignment) =>
        isJobSelectedInQuickAssignMode(splitEventAssignment.provider.providerid)
    )
  }, [assignment.additional_split_event_assignments])

  const isAssignmentUnderstaffed = useMemo(() => {
    let currentAssignedCount = 0
    if (assignment.draft_eventid) {
      if (assignment.draft_additional_event_assignments) {
        currentAssignedCount =
          assignment.draft_additional_event_assignments.length + 1
      } else {
        currentAssignedCount = 1
      }
    } else {
      if (assignment.additional_event_assignments) {
        currentAssignedCount =
          assignment.additional_event_assignments.length + 1
      } else {
        currentAssignedCount = 1
      }
    }

    if (assignment.draft_eventid) {
      const minimum = jobs
        .find((job) => job.jobid === assignment.jobid)
        ?.multipleAssignmentsSettings?.find(
          (setting) => setting.date === assignment.edate
        )?.minimum
      if (minimum) {
        return (
          isUnderstaffedJobsHighlightActive && currentAssignedCount < minimum
        )
      }
    }

    return (
      isUnderstaffedJobsHighlightActive &&
      assignment.job_day_assignment &&
      currentAssignedCount < assignment.job_day_assignment.minimum
    )
  }, [isUnderstaffedJobsHighlightActive, assignment])

  const getAssignmentUnderstaffedText = useCallback(() => {
    if (assignment.draft_eventid) {
      const minimum = jobs
        .find((job) => job.jobid === assignment.jobid)
        ?.multipleAssignmentsSettings?.find(
          (setting) => setting.date === assignment.edate
        )?.minimum

      const count = assignment.draft_additional_event_assignments
        ? assignment.draft_additional_event_assignments.length + 1
        : 1
      if (minimum) {
        return `${count}/${minimum}`
      }
    }

    const currentAssignedCount = assignment.additional_event_assignments
      ? assignment.additional_event_assignments.length + 1
      : 1

    return `${currentAssignedCount}/${assignment.job_day_assignment?.minimum}`
  }, [assignment, jobs])

  const isJobDisabled = isAdditionalJobDisabled || isSplitJobDisabled

  if (!isMultiOrSplitAssignment) {
    return (
      <AssignmentSingleJobElement
        assignment={assignment}
        showModal={showModal}
        isJobSelectedInQuickAssignMode={isJobSelectedInQuickAssignMode}
        quickMode={quickMode}
        handleDeleteQuickAssign={handleDeleteQuickAssign}
        isAssignmentUnderstaffed={isAssignmentUnderstaffed}
        getAssignmentUnderstaffedText={getAssignmentUnderstaffedText}
      />
    )
  }

  return (
    <AssignmentMultipleJobsElement
      assignment={assignment}
      isJobDisabled={isJobDisabled}
      showModal={showModal}
      isJobSelectedInQuickAssignMode={isJobSelectedInQuickAssignMode}
      isAssignmentUnderstaffed={isAssignmentUnderstaffed}
      getAssignmentUnderstaffedText={getAssignmentUnderstaffedText}
    />
  )
}
