import React, { memo } from "react"
import FlagIcon from "@material-ui/icons/Flag"
import { MultipleAssigmentJob } from "@app/containers/spa/WhiteboardCalendar/data"
import css from "./FlagDetail.module.scss"
import cx from "classnames"

interface FlagDetailProps {
  item: MultipleAssigmentJob
  date: string
  onFlagClick: () => void
  shouldShowFlag: boolean
}
const FlagDetail: React.FC<FlagDetailProps> = ({
  item,
  date,
  onFlagClick,
  shouldShowFlag,
}) => {
  const handleFlagClick = () => {
    if (shouldShowFlag || item.flag) {
      onFlagClick()
    }
  }

  const classes = cx(css.flagIcon, {
    [css.notAllowed]: !shouldShowFlag && !item.flag,
  })

  return (
    <FlagIcon
      style={{
        color: item.flag ? "#c83c31" : "#a5a5a5",
        pointerEvents: shouldShowFlag || item.flag ? "auto" : "none",
      }}
      className={classes}
      onClick={handleFlagClick}
    />
  )
}

export default memo(FlagDetail)
