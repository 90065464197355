import React, { useMemo, useCallback } from "react"
import css from "./AssignmentProviderElement.module.scss"
import { formatDateToStartEnd } from "@app/utils"
import { GroupedAssignment } from "@app/services/getGroupedDayAssignments"
import { JobDefaultColor } from "@app/utils/constants"
import cx from "classnames"
import { useSelector } from "@app/models"
import CustomTooltip from "../AssignmentJobElement/Tooltip"

interface AssignmentProviderElementProps {
  assignment: GroupedAssignment
  showModal: () => void
  isCardDisabled: boolean
  quickMode?: boolean
  handleDeleteQuickAssign?: (assignment: GroupedAssignment) => void
}

export const AssignmentProviderElement = ({
  assignment,
  showModal,
  isCardDisabled,
  quickMode,
  handleDeleteQuickAssign,
}: AssignmentProviderElementProps) => {
  const {
    showJobTimesInCalendar,
    filterOptions: {
      jobsFilters: { jobIds },
    },
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  const isJobFilterActive = useMemo(
    () => jobIds && jobIds?.length > 0,
    [jobIds]
  )

  const isAssignmentFiltered = useMemo(
    () => !jobIds?.includes(assignment.job?.jobid),
    [jobIds, assignment.job?.jobid]
  )

  const getFormattedJobTimes = () => {
    if (assignment.split_shift) {
      return formatDateToStartEnd(
        assignment.split_shift.starttime,
        assignment.split_shift.endtime
      )
    } else if (assignment.job) {
      return formatDateToStartEnd(
        assignment.job.starttime,
        assignment.job.endtime
      )
    }
    return null
  }

  const handleClick = useCallback(() => {
    if (quickMode) {
      handleDeleteQuickAssign?.(assignment)
    } else {
      showModal()
    }
  }, [quickMode, assignment])

  return (
    <button
      className={cx(css.assignmentWrapper, {
        [css.disabledButton]: isCardDisabled,
        [css.disabledCellJob]: isCardDisabled,
      })}
      onClick={handleClick}
      disabled={isCardDisabled}
    >
      <div
        className={cx(css.assignmentBorder)}
        style={{
          borderColor: isCardDisabled
            ? "#e6e6e6"
            : assignment.job?.color ?? JobDefaultColor,
          backgroundColor: isCardDisabled
            ? "#e6e6e6"
            : !assignment.draft_eventid
            ? assignment.job?.color ?? JobDefaultColor
            : "",
        }}
      />
      <div
        className={cx(css.assignmentDataWrapper, {
          [css.disabledCellJob]: isCardDisabled,
        })}
        style={{
          backgroundColor: assignment.draft_eventid ? "transparent" : "",
          borderColor: assignment.draft_eventid ? "#DADADA" : "",
        }}
      >
        {isJobFilterActive && isAssignmentFiltered ? (
          <CustomTooltip
            arrow
            title={
              <span
                className={css.assignmentTitleWrapper}
                style={{ color: assignment.draft_eventid ? "#A5A5A5" : "" }}
              >
                {assignment.job?.abbrev}
              </span>
            }
          >
            <span className={css.filteredText}>Filtered</span>
          </CustomTooltip>
        ) : (
          <>
            <span
              className={css.assignmentTitleWrapper}
              style={{ color: assignment.draft_eventid ? "#A5A5A5" : "" }}
            >
              {assignment.job?.abbrev}
            </span>
            <span
              className={css.assignmentDescriptionWrapper}
              style={{ color: assignment.draft_eventid ? "#A5A5A5" : "" }}
            >
              {showJobTimesInCalendar && getFormattedJobTimes()}
            </span>
          </>
        )}
      </div>
    </button>
  )
}
