import React, { useState } from "react"
import { Button } from "react-bootstrap"
import CheckIcon from "@material-ui/icons/Check"
import css from "./NoteItem.module.scss"

interface Props {
  data: Note
  onUpdateNote: (id: number, content: string) => Promise<void>
  onDeleteNote: (id: number) => Promise<void>
}

export const NoteItem = ({ data, onUpdateNote, onDeleteNote }: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [selectedNote, setSelectedNote] = useState<Note>()
  const [newContent, setNewContent] = useState<string>(data.note)
  const [showOnCalendar, setShowOnCalendar] = useState<boolean>(
    data.show_on_calendar
  )

  const handleUpdateNote = () => {
    if (!selectedNote) return
    if (newContent !== selectedNote?.note) {
      onUpdateNote(selectedNote.noteid, newContent)
    }
    setIsEditing(false)
  }

  return (
    <li className={css.container}>
      <h6 className={css.authorNameContainer}>
        <span className={css.nameTitle}>Name:</span>
        <span>
          {data.user?.firstname} {data.user?.lastname}
        </span>
      </h6>
      <div className={css.contentContainer}>
        {isEditing ? (
          <>
            <textarea
              className={css.textArea}
              value={newContent}
              onChange={(e) => setNewContent(e.target.value)}
            />
            <button
              type="button"
              className={css.checkboxContainer}
              onClick={() => setShowOnCalendar(!showOnCalendar)}
            >
              <div className={css.checkIconContainer}>
                {showOnCalendar && <CheckIcon className={css.checkIcon} />}
              </div>
              <div>Show in calendar</div>
            </button>
            <div className={css.buttonsContainer}>
              <Button className={css.textButton} onClick={handleUpdateNote}>
                Save
              </Button>
              <Button
                className={css.textButton}
                onClick={() => {
                  setIsEditing(false)
                  setSelectedNote(undefined)
                }}
              >
                Cancel
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className={css.contentWrapper}>
              <p className={css.content}>{newContent}</p>
            </div>
            <div className={css.buttonsContainer}>
              <Button
                className={css.textButton}
                onClick={() => {
                  setIsEditing(true)
                  setSelectedNote(data)
                }}
              >
                Edit
              </Button>
              <Button
                className={css.textButton}
                onClick={() => onDeleteNote(data.noteid)}
              >
                Delete
              </Button>
            </div>
          </>
        )}
      </div>
    </li>
  )
}
