import React from "react"
import css from "./MenuItem.module.scss"

interface MenuItemProps {
  item: IMenuItem
}
interface ISubMenuItem {
  name: string
  link: string
  subMenu?: ISubMenuItem[]
}

interface IMenuItem {
  name: string
  link: string
  subMenu?: ISubMenuItem[]
}

const MenuItem: React.FC<MenuItemProps> = ({ item }) => {
  const renderSubMenu = (subMenu: ISubMenuItem[]) => (
    <ul className={css.subMenu}>
      {subMenu.map((subItem, index) => (
        <li key={index} className={css.subMenuElement}>
          <a className={css.subMenuLink} href={subItem.link}>
            {subItem.name}
          </a>
          {subItem.subMenu &&
            subItem.subMenu.length > 0 &&
            renderSubMenu(subItem.subMenu)}
        </li>
      ))}
    </ul>
  )

  return (
    <li className={css.navMainElement}>
      <a className={css.navMainLink} href={item.link}>
        {item.name}
      </a>
      {item.subMenu && item.subMenu.length > 0 && renderSubMenu(item.subMenu)}
    </li>
  )
}

export default MenuItem
