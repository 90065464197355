import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import * as yup from "yup"

const notesFormSchema = yup
  .object()
  .shape({
    content: yup.string().required("Content is a required field"),
    showOnCalendar: yup.boolean().required().default(false),
  })
  .required()

export type NotesFormValues = yup.InferType<typeof notesFormSchema>

export const useNotesForm = () => {
  const form = useForm<NotesFormValues>({
    mode: "onSubmit",
    resolver: yupResolver(notesFormSchema),
  })

  return form
}
