import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useMemo,
} from "react"
import css from "./CalendarListView.module.scss"
import AssignmentContentModal from "./AssignmentContentModal"
import { format, parseISO, addDays, isSameDay } from "date-fns"
import JobsTableModal from "./JobsTableModal/JobsTableModal"
import {
  Provider,
  Assignment,
  MultipleAssigmentJob,
  IRequest,
  IQuickProvider,
} from "@app/containers/spa/WhiteboardCalendar/data"
import { cloneDeep } from "lodash"
import { Flag } from "@material-ui/icons"
import RequestModal from "./RequestModal"
import { transformsDataMultipleAssigmentJobs } from "@app/services/transformDataMultipleAssignment"
import {
  AssignmentProviderElement,
  DayOffProviderElement,
  RequestProviderElement,
  AssignmentJobElement,
  VacationProviderElement,
} from "./CalendarListDayElements"
import {
  ProcessedHoliday,
  getHolidaysOffDate,
} from "@app/services/getOrderedHolidays"
import { getGroupedDayAssignments } from "@app/services/getGroupedDayAssignments"
import { useDispatch, useSelector } from "@app/models"
import { getUnassignedJobsByDay } from "@app/services/getUnassignedEvent"
import cx from "classnames"
import api from "@app/services/api"
import { handleApiError } from "@app/utils"
import { DefaultCalendarColor } from "@app/utils/constants"
import NotesModal from "./NotesModal"
import { getDailyNotes } from "@app/services/getDailyNotes"

const processAssignmentsByDate = (
  unProcessedAssignments: AssignmentBaseType[]
) => {
  const preprocessedAssignments: { [date: string]: Assignment[] } = {}
  unProcessedAssignments?.forEach((assignment) => {
    const dateKey = format(parseISO(assignment.edate), "yyyy-MM-dd")
    if (!preprocessedAssignments[dateKey]) {
      preprocessedAssignments[dateKey] = []
    }
    preprocessedAssignments[dateKey].push(assignment)
  })
  return preprocessedAssignments
}

type ListToDisplay = {
  id: string | number
  name: string
  color: string | null | undefined
  job_days_type?: JobDayType[]
}

interface CalendarProps {
  view: "Day" | "Week" | "Month"
  selectedDate: string
  periodCount: number
  jobsData: JobAssignment[]
  providersData: Provider[]
  notes: Note[]
  jobsFilteredAssignments: AssignmentBaseType[]
  providersFilteredAssignments: AssignmentBaseType[]
  flags: ScheduleDateType[]
  dayoffs: DayOff[]
  vacations: Vacation[]
  requests: IRequest[]
  daysOffTypes: any
  requestStatus: any
  orderedHolidays: ProcessedHoliday[]
  refreshAssignments: () => void
  refreshFlags: () => void
  quickMode: boolean
  selectedProvider?: Provider
  setQuickAssigneePanelOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const formatDate = (date: string): string =>
  format(parseISO(date), "yyyy-MM-dd")

const ListViewCalendar = ({
  view,
  selectedDate,
  periodCount,
  jobsData,
  providersData,
  jobsFilteredAssignments,
  providersFilteredAssignments,
  flags,
  dayoffs,
  vacations,
  requests,
  orderedHolidays,
  notes,
  refreshAssignments,
  refreshFlags,
  quickMode,
  setQuickAssigneePanelOpen,
}: CalendarProps) => {
  const [isProviderView, setIsProviderView] = useState(false)
  const [selectedAssignment, setSelectedAssignment] =
    useState<AssignmentBaseType>()
  const [jobsFilteredAssignmentsByDate, setJobsFilteredAssignmentsByDate] =
    useState<{
      [date: string]: AssignmentBaseType[]
    }>({})
  const [
    providersFilteredAssignmentsByDate,
    setProvidersFilteredAssignmentsByDate,
  ] = useState<{
    [date: string]: AssignmentBaseType[]
  }>({})
  const [flagsByDate, setFlagsByDate] = useState<{
    [date: string]: ScheduleDateType[]
  }>({})
  const datesToShow = calculateDatesToShow(selectedDate, view, periodCount)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalDateOpen, setIsModalDateOpen] = useState(false)
  const [formattedDate, setFormattedDate] = useState<string>("")
  const [selectedRequestid, setSelectedRequestid] = useState<number | null>()
  const [currentDate, setCurrentDate] = useState("")

  const { jobs } = useSelector((state) => state.groupData)

  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)
  const [selectedCurrentDate, setSelectedCurrentDate] = useState("")
  const dayoffsByDate: Record<string, DayOff[]> = {}
  const { draft_mode_scheduling } = useSelector(
    (state) => state.calendarEvents.calendarConfig.rulesConfig
  )
  dayoffs?.forEach((dayoff) => {
    if (dayoffsByDate[dayoff.date]) {
      dayoffsByDate[dayoff.date] = [...dayoffsByDate[dayoff.date], dayoff]
    } else {
      dayoffsByDate[dayoff.date] = [dayoff]
    }
  })

  const {
    selectedJob,
    selectedProvider,
    isUnderstaffedJobsHighlightActive,
    compactListView,
    highlightOptions: { highlightProviders },
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  const providersAreHighlighted = useMemo(
    () => highlightProviders && highlightProviders.length > 0,
    [highlightProviders]
  )

  const requestsByDate: Record<string, IRequestType[]> = {}
  requests?.forEach((request) => {
    const startDate = new Date(request.start_date)
    const endDate = new Date(request.end_date)
    if (startDate > endDate) return

    let beginning = startDate
    while (beginning <= endDate) {
      let index = beginning.toISOString().split("T")[0]
      if (requestsByDate[index]) {
        requestsByDate[index] = [...requestsByDate[index], request]
      } else {
        requestsByDate[index] = [request]
      }
      var newDate = beginning.setDate(beginning.getDate() + 1)
      beginning = new Date(newDate)
    }
  })

  const vacationsByDate: Record<string, Vacation[]> = {}
  vacations?.forEach((vacation: Vacation) => {
    const date = vacation.link_date.link_date
    if (vacationsByDate[date]) {
      vacationsByDate[date] = [...vacationsByDate[date], vacation]
    } else {
      vacationsByDate[date] = [vacation]
    }
  })

  const dispatch = useDispatch()

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIsProviderView(e.target.value === "provider")
    dispatch.calendarEvents.setCalendarListViewMode(
      e.target.value as "job" | "provider"
    )
    setQuickAssigneePanelOpen(false)
    dispatch.calendarEvents.setSelectedProvider(undefined)
    dispatch.calendarEvents.setSelectedJob(undefined)
  }

  useEffect(() => {
    setJobsFilteredAssignmentsByDate(
      processAssignmentsByDate(jobsFilteredAssignments)
    )
  }, [jobsFilteredAssignments])

  useEffect(() => {
    setProvidersFilteredAssignmentsByDate(
      processAssignmentsByDate(providersFilteredAssignments)
    )
  }, [providersFilteredAssignments])

  useEffect(() => {
    const preprocessedFlags: { [date: string]: ScheduleDateType[] } = {}
    flags?.forEach((flag) => {
      const dateKey = format(parseISO(flag.link_date.link_date), "yyyy-MM-dd")
      if (!preprocessedFlags[dateKey]) {
        preprocessedFlags[dateKey] = []
      }
      preprocessedFlags[dateKey].push(flag)
    })
    setFlagsByDate(preprocessedFlags)
  }, [flags])

  const showModal = (edate: string, item: any, jobid: number) => {
    const formattedDate = formatDate(edate)
    let dayAssignments: AssignmentBaseType[] = []

    if (isProviderView) {
      dayAssignments = providersFilteredAssignmentsByDate[formattedDate] || []
    } else {
      dayAssignments = jobsFilteredAssignmentsByDate[formattedDate] || []
    }

    let fullAssignment = null
    for (let assignment of dayAssignments) {
      if (assignment.jobid === jobid && assignment.edate === edate) {
        fullAssignment = assignment
        break
      }
      const additionalMatch = assignment.additional_event_assignments?.find(
        (add) => add.jobid === jobid || add.eventid === jobid
      )
      if (additionalMatch) {
        fullAssignment = {
          ...assignment,
          additional_event_assignments: [additionalMatch],
        }
        break
      }
    }

    if (fullAssignment) {
      setSelectedAssignment(cloneDeep(fullAssignment))
    } else if (!isProviderView && item.id) {
      setSelectedAssignment({ jobid: item.id, providerid: "", edate } as any)
    } else {
      setSelectedAssignment(undefined)
    }
    setIsModalOpen(true)
  }

  const hideModal = () => {
    setSelectedAssignment(undefined)
    setIsModalOpen(false)
    refreshAssignments()
  }

  const showDateModal = (date: string) => {
    const formattedDate = formatDate(date)
    setFormattedDate(formattedDate)
    setCurrentDate(date)
    setIsModalDateOpen(true)
  }

  const jobsDataModal =
    formattedDate &&
    transformsDataMultipleAssigmentJobs(
      jobsFilteredAssignmentsByDate[formattedDate] || [],
      flags,
      jobsData,
      formattedDate
    )

  const hideDateModal = () => {
    refreshFlags()
    setIsModalDateOpen(false)
  }

  const handleNotesModalOpen = (edate: string) => {
    setIsNotesModalOpen(true)
    const parsedDate = format(parseISO(edate), "EEEE, MM/dd/yy")
    setSelectedCurrentDate(parsedDate)
  }

  const handleNotesModalClose = () => {
    setIsNotesModalOpen(false)
    setSelectedCurrentDate("")
  }

  const getIsProviderCardDisabled = (jobId: number) => {
    return Boolean(selectedJob) && jobId !== selectedJob?.jobid
  }

  const getNoAssignmentText = (
    jobDayType: JobDayType,
    isProviderView: boolean
  ) => {
    if (jobDayType && jobDayType.assign === null) {
      return "Unavailable"
    } else if (isProviderView) {
      return "No assignments"
    } else {
      return "No providers"
    }
  }

  const renderNumberOfUnderstaffedJobs = (
    dates: string[],
    jobId: number,
    job_days_type: JobDayType[] | undefined
  ) => {
    let counter = 0
    dates.forEach((date) => {
      let internalDayCounter = 0
      const jobsFilteredDayAssignments =
        jobsFilteredAssignmentsByDate[date] || []

      const groupedDayJobsFilteredDayAssignments = getGroupedDayAssignments(
        jobsFilteredDayAssignments
      )
      groupedDayJobsFilteredDayAssignments?.forEach((assignment) => {
        if (assignment.job.jobid === jobId) {
          if (assignment.draft_eventid) {
            const minimum = jobs
              .find((job) => job.jobid === assignment.jobid)
              ?.multipleAssignmentsSettings?.find(
                (setting) => setting.date === assignment.edate
              )?.minimum

            const count = assignment.draft_additional_event_assignments
              ? assignment.draft_additional_event_assignments.length + 1
              : 1
            if (minimum && count < minimum) {
              counter = counter + 1
            }
          } else {
            const currentAssignedCount = assignment.additional_event_assignments
              ? assignment.additional_event_assignments.length + 1
              : 1

            if (
              isUnderstaffedJobsHighlightActive &&
              assignment.job_day_assignment &&
              currentAssignedCount < assignment.job_day_assignment.minimum
            ) {
              counter = counter + 1
            }
          }

          internalDayCounter = internalDayCounter + 1
        }
      })

      const dayName = format(parseISO(date), "EEEE")
      const jobDayType = job_days_type
        ? job_days_type.find((day: JobDayType) => day.day === dayName)
        : undefined

      const tableCellUnavailable =
        jobDayType &&
        getNoAssignmentText(jobDayType, isProviderView) === "Unavailable"

      if (internalDayCounter === 0 && !tableCellUnavailable) {
        counter = counter + 1
      }
    })
    return counter > 0 ? (
      <div className={css.jobUnderstaffedCounter}>{counter}</div>
    ) : null
  }

  const handleQuickModeAssignment = async (
    assignmentDate: string,
    item: AssignmentBaseType | IQuickProvider
  ) => {
    const jobId = getJobId(isProviderView, selectedJob, item)
    const providerId = getProviderId(isProviderView, selectedProvider, item)

    if (handleQuickModeAssignment.isProcessing) return
    handleQuickModeAssignment.isProcessing = true

    try {
      if (quickMode) {
        await processQuickModeAssignment(
          isProviderView,
          selectedJob,
          selectedProvider,
          assignmentDate,
          jobId,
          providerId
        )
      } else {
        handleNonQuickModeAssignment(
          isProviderView,
          assignmentDate,
          item,
          jobId
        )
      }
    } catch (error) {
      handleApiError
    } finally {
      handleQuickModeAssignment.isProcessing = false
    }
  }

  handleQuickModeAssignment.isProcessing = false

  const getJobId = (isProviderView: boolean, selectedJob: any, item: any) => {
    return isProviderView ? Number(selectedJob?.jobid) : Number(item?.id)
  }

  const getProviderId = (
    isProviderView: boolean,
    selectedProvider: any,
    item: any
  ) => {
    return isProviderView
      ? Number((item as IQuickProvider).id)
      : Number(selectedProvider?.providerid)
  }

  const processQuickModeAssignment = async (
    isProviderView: boolean,
    selectedJob: any,
    selectedProvider: any,
    assignmentDate: string,
    jobId: number,
    providerId: number
  ) => {
    const isValidSelection = isProviderView
      ? Boolean(selectedJob?.jobid)
      : Boolean(selectedProvider?.providerid)
    if (!isValidSelection) return

    const singleQuickAssignment = await api.createSingleAssignment(
      assignmentDate,
      jobId,
      providerId,
      draft_mode_scheduling
    )

    dispatch.quickAssignments.addQuickAssignment(singleQuickAssignment)
    refreshAssignments()
  }

  const handleNonQuickModeAssignment = (
    isProviderView: boolean,
    assignmentDate: string,
    item: AssignmentBaseType | IQuickProvider,
    jobId: number
  ) => {
    if (isProviderView) {
      showDateModal(assignmentDate)
    } else {
      showModal(assignmentDate, item as AssignmentBaseType, jobId)
    }
  }

  const handleDeleteQuickAssign = async (assignment: AssignmentBaseType) => {
    const id = assignment?.draft_eventid || assignment?.eventid
    const deleteAssigment = assignment?.draft_eventid
      ? api.deleteDraftEvent
      : api.deleteEvent

    if (id) {
      try {
        await deleteAssigment(id)
        dispatch.quickAssignments.removeQuickAssignmentAndViolation(id)
        refreshAssignments()
      } catch (error) {
        handleApiError
      }
    }
  }

  const renderAssignmentForDate = useCallback(
    (date: string, item: any) => {
      let noAssignmentKeyCounter = 0
      const formattedDate = format(parseISO(date), "yyyy-MM-dd")
      const providersFilteredDayAssignments =
        providersFilteredAssignmentsByDate[formattedDate] || []

      const jobsFilteredDayAssignments =
        jobsFilteredAssignmentsByDate[formattedDate] || []

      const groupedDayJobsFilteredDayAssignments = getGroupedDayAssignments(
        jobsFilteredDayAssignments
      )

      const dayName = format(parseISO(date), "EEEE")
      const jobDayType = item.job_days_type
        ? item.job_days_type.find((day: JobDayType) => day.day === dayName)
        : null
      let matchedAssignments = []

      if (isProviderView) {
        const dailyDayoffs = dayoffsByDate[formattedDate] || []
        const dailyRequests = requestsByDate[formattedDate] || []
        const dailyVacations = vacationsByDate[formattedDate] || []

        providersFilteredDayAssignments?.forEach((assignment) => {
          if (assignment.provider.providerid === item.id) {
            matchedAssignments.push(
              <AssignmentProviderElement
                key={`provider-${assignment.providerid}-${
                  assignment.eventid ?? assignment?.draft_eventid
                }`}
                assignment={assignment}
                showModal={() => showModal(date, item, assignment.jobid)}
                isCardDisabled={getIsProviderCardDisabled(assignment.job.jobid)}
                quickMode={quickMode}
                handleDeleteQuickAssign={() =>
                  handleDeleteQuickAssign(assignment)
                }
              />
            )
          }

          assignment?.additional_event_assignments?.forEach(
            (additionalAssignment) => {
              if (additionalAssignment.provider.providerid === item.id) {
                matchedAssignments.push(
                  <AssignmentProviderElement
                    key={`provider-${additionalAssignment.providerid}-${additionalAssignment?.id}`}
                    assignment={additionalAssignment}
                    showModal={() =>
                      showModal(date, item, additionalAssignment.job?.jobid)
                    }
                    isCardDisabled={getIsProviderCardDisabled(
                      additionalAssignment?.job?.jobid
                    )}
                    quickMode={quickMode}
                  />
                )
              }
            }
          )

          assignment?.draft_additional_event_assignments?.forEach(
            (additionalAssignment) => {
              if (additionalAssignment.provider.providerid === item.id) {
                matchedAssignments.push(
                  <AssignmentProviderElement
                    key={`provider-${additionalAssignment.providerid}-${additionalAssignment?.id}`}
                    assignment={{
                      ...additionalAssignment,
                      job: assignment.job,
                    }}
                    showModal={() =>
                      showModal(date, item, additionalAssignment.job?.jobid)
                    }
                    isCardDisabled={getIsProviderCardDisabled(
                      additionalAssignment?.job?.jobid
                    )}
                    quickMode={quickMode}
                  />
                )
              }
            }
          )
        })

        dailyDayoffs?.forEach((dayoff: DayOff) => {
          if (dayoff.providerid === item.id) {
            matchedAssignments.push(
              <DayOffProviderElement
                key={`dayoff-${dayoff.dayoffid}`}
                dayOffTypeName={dayoff.type_name}
                isCardDisabled={Boolean(selectedJob?.jobid)}
              />
            )
          }
        })

        if (dailyVacations.length > 0) {
          dailyVacations.forEach((vacation) => {
            if (vacation.providerid === item.id) {
              matchedAssignments.push(
                <VacationProviderElement
                  isCardDisabled={Boolean(selectedJob)}
                  key={`vacation-${vacation.vacationid}`}
                />
              )
            }
          })
        }

        dailyRequests?.forEach((request: IRequestType) => {
          if (request.providerid === item.id) {
            matchedAssignments.push(
              <RequestProviderElement
                key={`request-${request.requestid}`}
                request={request}
                setSelectedRequestid={() =>
                  setSelectedRequestid(request.requestid)
                }
                isCardDisabled={getIsProviderCardDisabled(request.jobid)}
              />
            )
          }
        })
      } else if (!isProviderView && typeof item !== "string") {
        const dayFlags = flagsByDate[formattedDate] || []
        const dailyUnassigned = getUnassignedJobsByDay(
          jobsData,
          date,
          jobsFilteredDayAssignments
        )

        if (!compactListView) {
          dailyUnassigned?.forEach((unassigned) => {
            if (unassigned.jobid === item.id) {
              matchedAssignments.push(
                <button
                  onClick={() => handleQuickModeAssignment(date, item)}
                  className={cx(css.unassignedContainer, {
                    [css.unassignedFadedContainer]: providersAreHighlighted,
                  })}
                  key={`unassigned-${date}-${unassigned.jobid}`}
                >
                  <span className={css.noAssignment}>Unassigned</span>
                  {isUnderstaffedJobsHighlightActive && (
                    <div className={css.understaffedCounter}>{`0/${
                      jobs
                        .find((job) => job.jobid === unassigned.jobid)
                        ?.multipleAssignmentsSettings?.find(
                          (setting) => setting.date === date
                        )?.minimum
                    }`}</div>
                  )}
                </button>
              )
            }
          })
        }

        groupedDayJobsFilteredDayAssignments?.forEach((assignment) => {
          if (assignment.job.jobid === item.id) {
            matchedAssignments.push(
              <AssignmentJobElement
                assignment={assignment}
                quickMode={quickMode}
                showModal={() => showModal(date, item, assignment.jobid)}
                handleDeleteQuickAssign={() =>
                  handleDeleteQuickAssign(assignment)
                }
                key={`job-${assignment.draft_eventid ?? assignment.eventid}-${
                  assignment.jobid
                }-${assignment.providerid}`}
              />
            )
          }
        })

        dayFlags?.forEach((flag) => {
          if (flag.job.abbrev === item.name) {
            matchedAssignments.push(
              <Fragment key={`job-${flag.jobid}-${flag.link_dateid}`}>
                <div
                  onClick={() => showModal(date, item, flag.jobid)}
                  className={css.JobContainer}
                >
                  <Flag style={{ color: "red" }} className={css.flagIcon} />
                </div>
              </Fragment>
            )
          }
        })
      }

      if (matchedAssignments.length === 0) {
        const tableCellUnavailable =
          getNoAssignmentText(jobDayType, isProviderView) === "Unavailable"

        matchedAssignments.push(
          <button
            onClick={() => {
              if (jobDayType && jobDayType.assign === null) {
                return
              }
              handleQuickModeAssignment(date, item)
            }}
            className={cx(css.emptyContainer, {
              [css.disabledContainer]: tableCellUnavailable,
            })}
            key={`no-assignment-${date}-${noAssignmentKeyCounter++}`}
            disabled={tableCellUnavailable}
          >
            <p className={css.noAssignment}>
              {compactListView
                ? ""
                : getNoAssignmentText(jobDayType, isProviderView)}
            </p>
            {isUnderstaffedJobsHighlightActive && !tableCellUnavailable && (
              <div className={css.understaffedCounter}>{`0/${
                jobs
                  .find((job) => job.jobid === item.id)
                  ?.multipleAssignmentsSettings?.find(
                    (setting) => setting.date === date
                  )?.minimum
              }`}</div>
            )}
          </button>
        )
      }

      return matchedAssignments
    },
    [
      isProviderView,
      providersFilteredAssignmentsByDate,
      jobsFilteredAssignmentsByDate,
    ]
  )

  const listToDisplay: ListToDisplay[] = useMemo(
    () =>
      isProviderView
        ? providersData.map((provider) => ({
            id: provider.providerid,
            name: provider.display_name,
            color: provider.provider_color,
          }))
        : jobsData.map((job) => ({
            id: job.jobid,
            name: job.abbrev,
            color: job.color,
            job_days_type: job.job_day_types,
          })),
    [isProviderView, providersData, jobsData]
  )

  const noInformationAvailable = listToDisplay.length === 0

  return (
    <div className={css.listViewContainer}>
      <div className={css.tableScroll}>
        <table className={css.listViewTable}>
          <thead>
            <tr>
              <th className={css.listViewHeader}>
                <select
                  onChange={handleSelectChange}
                  className={css.listViewSelect}
                >
                  <option value="job">Job</option>
                  <option value="provider">Provider</option>
                </select>
              </th>
              {datesToShow.map((date, index) => {
                const parsedDate = compactListView
                  ? format(parseISO(date), "EEE")
                  : format(parseISO(date), "eee, MMM d")
                const holidaysByDate = getHolidaysOffDate(date, orderedHolidays)
                const isToday = isSameDay(new Date(parseISO(date)), new Date())
                const dailyNotes = getDailyNotes(
                  notes,
                  new Date(parseISO(date))
                )

                return (
                  <th
                    key={index}
                    className={cx(css.listViewHeaderDate, {
                      [css.listViewWeekend]:
                        parsedDate.includes("Sat") ||
                        parsedDate.includes("Sun"),
                      [css.todayOutlined]: isToday,
                    })}
                  >
                    <div className={css.listViewHeaderDateContainer}>
                      <div className={css.listViewHeaderDateTitleContainer}>
                        <button
                          className={css.listViewHeaderDateParseDate}
                          onClick={() => showDateModal(date)}
                        >
                          <span>{parsedDate}</span>
                          {compactListView && (
                            <span>{format(parseISO(date), "MMM d")}</span>
                          )}
                        </button>

                        {dailyNotes.length > 0 ? (
                          <button
                            onClick={() => handleNotesModalOpen(date)}
                            className={css.noteButton}
                          >
                            Notes
                          </button>
                        ) : null}
                      </div>
                      {holidaysByDate.map((holidayByDate) => (
                        <div
                          className={cx(css.listViewHeaderDateHoliday, {
                            [css.listViewHeaderDateWeekendHoliday]:
                              parsedDate.includes("Saturday") ||
                              parsedDate.includes("Sunday"),
                          })}
                          key={holidayByDate.holidayId}
                        >
                          {compactListView
                            ? holidayByDate.abbrev
                            : holidayByDate.name}
                        </div>
                      ))}
                    </div>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {noInformationAvailable ? (
              <tr>
                <td
                  colSpan={datesToShow.length + 1}
                  className={css.noInformationCell}
                >
                  No information available
                </td>
              </tr>
            ) : (
              listToDisplay.map((item, index) => (
                <tr key={index}>
                  <td
                    className={cx(css.listViewCell, {
                      [css.miniListViewCell]: compactListView,
                    })}
                  >
                    {isProviderView ? (
                      <div className={css.headListProvider}>
                        <div className={css.providerWrapper}>
                          <div
                            className={css.providerCellCircle}
                            style={{
                              background: item.color || DefaultCalendarColor,
                            }}
                          />
                          <div className={css.providerLabelName}>
                            {item.name}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={css.headListJob}>
                        <div
                          className={css.jobCellRectangle}
                          style={{
                            background: item.color || DefaultCalendarColor,
                          }}
                        />
                        <div className={css.providerLabelName}>{item.name}</div>
                        {isUnderstaffedJobsHighlightActive &&
                          renderNumberOfUnderstaffedJobs(
                            datesToShow,
                            Number(item.id),
                            item.job_days_type
                          )}
                      </div>
                    )}
                  </td>

                  {datesToShow.map((date, dateIndex) => {
                    return (
                      <td
                        key={`${index}-${dateIndex}`}
                        className={cx(css.listViewCellJobs, {
                          [css.miniListViewCellJobs]: compactListView,
                        })}
                      >
                        <div
                          className={
                            isProviderView
                              ? css.assignmentProviderContainer
                              : css.assignmentJobsContainer
                          }
                        >
                          <Fragment>
                            {renderAssignmentForDate(date, item)}
                          </Fragment>
                        </div>
                      </td>
                    )
                  })}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {selectedAssignment && (
        <AssignmentContentModal
          show={isModalOpen}
          hideModal={hideModal}
          edate={selectedAssignment.edate}
          jobid={selectedAssignment.jobid}
        />
      )}
      {selectedRequestid && (
        <RequestModal
          requestid={selectedRequestid}
          onHide={() => setSelectedRequestid(null)}
        />
      )}
      <JobsTableModal
        centered={true}
        isModalDateOpen={isModalDateOpen}
        onHide={hideDateModal}
        date={formattedDate}
        currentDate={currentDate}
        onCancel={hideDateModal}
        onSave={hideDateModal}
        closeButton={true}
        size="xl"
        jobsData={jobsDataModal as unknown as MultipleAssigmentJob[]}
      />

      <NotesModal
        date={selectedCurrentDate}
        isModalOpen={isNotesModalOpen}
        onCloseModal={handleNotesModalClose}
        closeButton
      />
    </div>
  )
}

const calculateDatesToShow = (
  selectedDate: string,
  view: string,
  periodCount: number
) => {
  let dates = []
  const start = parseISO(selectedDate)
  if (view === "Day") {
    for (let i = 0; i < periodCount; i++) {
      dates.push(format(addDays(start, i), "yyyy-MM-dd"))
    }
  } else if (view === "Week") {
    for (let i = 0; i < periodCount * 7; i++) {
      dates.push(format(addDays(start, i), "yyyy-MM-dd"))
    }
  } else if (view === "Month") {
    for (let i = 0; i < 30 * periodCount; i++) {
      dates.push(format(addDays(start, i), "yyyy-MM-dd"))
    }
  }
  return dates
}

export default ListViewCalendar
