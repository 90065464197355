import React, { useEffect, useMemo, useState, useCallback, memo } from "react"
import MenuItem from "@material-ui/core/MenuItem"
import ListSubheader from "@material-ui/core/ListSubheader"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import ListItemText from "@material-ui/core/ListItemText"
import { ProvidersRules } from "@app/utils/constants"
import css from "./ProvidersCustomSelect.module.scss"
import useRequest from "@app/utils/request"
import api from "@app/services/api"
import { TransformedProvider } from "../../data"
import { orderProvidersFunc } from "@app/utils/sorts"
import { DefaultCalendarColor } from "@app/utils/constants"
import { makeStyles } from "@material-ui/core"

interface Provider {
  id: string
  name: string
  available: boolean
  color: string
  violation?: string
}

const DEFAULT_PROVIDER_COLOR = DefaultCalendarColor

interface SelectComponentProps {
  jobid: string
  edate: string
  resetSelectionTrigger: number
  defaultProviders: TransformedProvider[]
  onChange: (defaultProviderId: number) => void
  defaultProviderId?: string
  setRuledProvidersMappings?: (mappings: any) => void
  disabled?: boolean
  allowUnavailable?: boolean
}

const ProvidersCustomSelectComponent: React.FC<SelectComponentProps> = ({
  jobid,
  edate,
  defaultProviders,
  setRuledProvidersMappings,
  defaultProviderId,
  onChange,
  disabled,
  allowUnavailable = false,
}) => {
  const [selectedProvider, setSelectedProvider] = useState<string>(
    defaultProviderId || ""
  )
  const usingDefaultProviders = !jobid && !edate

  const useStyles = makeStyles({
    customSelect: {
      height: "35px",
      "& .MuiSelect-outlined": {
        padding: "8px !important",
      },
      "& .MuiSvgIcon-root": {
        height: "16px",
        width: "16px",
      },
      "& .MuiSelect-selectMenu": {
        fontSize: "1.1rem",
        fontWeight: "600",
        lineHeight: "20px",
        color: "#767578",
      },
      "& .MuiListItemText-root": {
        maxWidth: "calc(100% - 34px)",
      },
    },
  })

  const classes = useStyles()

  const { data: providersWithRules, error } = useRequest(
    jobid && edate
      ? [
          api.getProvidersV3,
          { jobid: Number(jobid), date: edate, rules: ProvidersRules },
        ]
      : null
  )

  const getProvidersList = useCallback(
    (providersWithRules) => {
      const availableProviders: Provider[] = []
      const unavailableProviders: Provider[] = []

      providersWithRules
        .sort(orderProvidersFunc)
        .forEach((apiProvider: any) => {
          const color = apiProvider.provider_color || DEFAULT_PROVIDER_COLOR
          const providerObj: Provider = {
            id: apiProvider.providerid.toString(),
            name: `${apiProvider.display_name} - ${apiProvider.firstname} ${apiProvider.lastname}`,
            available: apiProvider.rulesApplied.length === 0,
            color,
          }
          if (providerObj.available) {
            availableProviders.push(providerObj)
          } else {
            providerObj.violation = apiProvider.rulesApplied[0].message
            unavailableProviders.push(providerObj)
          }
        })

      if (setRuledProvidersMappings) {
        setRuledProvidersMappings((prevState: any) => {
          const newState = {
            ...prevState,
            [jobid]: { availableProviders, unavailableProviders },
          }
          return prevState[jobid] &&
            JSON.stringify(prevState[jobid]) === JSON.stringify(newState[jobid])
            ? prevState
            : newState
        })
      }

      return [...availableProviders, ...unavailableProviders]
    },
    [jobid, setRuledProvidersMappings]
  )

  const providers = useMemo(() => {
    return providersWithRules
      ? getProvidersList(providersWithRules)
      : defaultProviders.map((provider) => ({
          id: provider.providerid,
          name: ` ${provider.display_name} - ${provider.firstname} ${provider.lastname}`,
          available: true,
          color: provider.provider_color || DEFAULT_PROVIDER_COLOR,
        }))
  }, [getProvidersList, providersWithRules])

  const handleSelectChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const selectedId = event.target.value as string
      const selectedProvider = providers.find((p) => p.id === selectedId)
      if (allowUnavailable && selectedProvider) {
        setSelectedProvider(selectedId)
        onChange(Number(selectedId))
      } else if (selectedProvider && selectedProvider.available) {
        setSelectedProvider(selectedId)
        onChange(Number(selectedId))
      }
    },
    [providers, onChange]
  )

  useEffect(() => {
    if (providers && defaultProviderId) {
      setSelectedProvider(String(defaultProviderId))
    } else {
      setSelectedProvider("")
    }
  }, [providers, defaultProviderId])

  if (error) {
    return <div>Error loading providers</div>
  }

  return (
    <FormControl variant="outlined" className={css.formControl}>
      <Select
        id="grouped-select"
        value={selectedProvider}
        defaultValue={selectedProvider || ""}
        onChange={handleSelectChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        disabled={disabled}
        style={{ maxWidth: "200px" }}
        className={classes.customSelect}
      >
        <MenuItem value="" disabled className={css.defaultSelect}>
          Select Provider
        </MenuItem>
        <ListSubheader className={css.availableLabel}>Available</ListSubheader>
        {providers.filter((p) => p.available).length > 0 ? (
          providers
            .filter((p) => p.available)
            .map((provider) => (
              <MenuItem
                value={provider.id}
                key={provider.id}
                className={css.providerName}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    className={css.statusDot}
                    style={{ backgroundColor: provider.color }}
                  ></span>
                  <ListItemText
                    className={css.providerName}
                    primary={provider.name}
                  />
                </div>
              </MenuItem>
            ))
        ) : (
          <MenuItem disabled className={css.noProviders}>
            No Available Providers
          </MenuItem>
        )}
        <ListSubheader className={css.availableLabel}>
          Unavailable
        </ListSubheader>
        {!usingDefaultProviders &&
        providers.filter((p) => !p.available).length > 0 ? (
          providers
            .filter((p) => !p.available)
            .map((provider) => (
              <MenuItem
                className={css.providerName}
                value={provider.id}
                key={provider.id}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    className={css.statusDot}
                    style={{ backgroundColor: provider.color }}
                  ></span>
                  <ListItemText
                    className={css.providerName}
                    primary={provider.name}
                  />
                </div>
              </MenuItem>
            ))
        ) : (
          <MenuItem disabled className={css.noProviders}>
            No Unavailable Providers
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

export default memo(ProvidersCustomSelectComponent)
