import React from "react"
import Table from "@app/components/Table"
import { H5 } from "@app/components/Typography"
import { ProviderDefaultColor } from "@app/utils/constants"
import cx from "classnames"
import css from "./AvailableProviders.module.scss"

interface Provider {
  id: string
  name: string
  available: boolean
  color: string
  violation?: string
}

type Props = {
  jobId: number
  setSelectedJobId?: (jobId: number | undefined) => void
  ruledProvidersMappings: any
}

export default (props: Props) => {
  const { jobId, ruledProvidersMappings } = props

  const availableProviders = ruledProvidersMappings[`${jobId}`]
    ? ruledProvidersMappings[`${jobId}`].availableProviders
    : []
  const unavailableProviders = ruledProvidersMappings[`${jobId}`]
    ? ruledProvidersMappings[`${jobId}`].unavailableProviders
    : []

  const availableProvidersColumns = [
    {
      title: "Available Providers",
      dataKey: "name",
      render: (name: string, provider: Provider) => {
        return (
          <>
            <H5
              ellipsis
              prefixDotColor={provider.color || ProviderDefaultColor}
            >
              {name}
            </H5>
          </>
        )
      },
    },
  ]

  const unavailableProvidersColumns = [
    {
      title: "Unavailable Providers",
      dataKey: "name",
      ellipsis: true,
      render: (name: string, provider: Provider) => {
        return (
          <>
            <H5
              ellipsis
              prefixDotColor={provider.color || ProviderDefaultColor}
            >
              {name}
            </H5>
          </>
        )
      },
      width: "50%",
    },
    {
      title: "Reason",
      dataKey: "violation",
      width: "50%",
      ellipsis: true,
    },
  ]

  return (
    <>
      <Table
        className={cx("table-bordered", css.ruleFilteredProviders)}
        striped={false}
        data={availableProviders}
        columns={availableProvidersColumns}
      />
      <Table
        className={cx("table-bordered", css.ruleFilteredProviders)}
        striped={false}
        data={unavailableProviders}
        columns={unavailableProvidersColumns}
      />
    </>
  )
}
