import React, { useMemo } from "react"
import cx from "classnames"
import css from "./AssignmentMultipleJobElement.module.scss"
import { GroupedAssignment } from "@app/services/getGroupedDayAssignments"
import { useSelector } from "@app/models"
import { getAdditionalAssignmentHighlighted } from "@app/services/getAdditionalAssignmentHighlighted"
import CustomTooltip from "../Tooltip"
import { AssignmentDetails } from "../AssignmentDetails"
import { getIsMultipleAssignmentHighlighted } from "../utils/getIsMultipleAssignmentHighlighted"
import { getIsAssignmentFiltered } from "../utils/getIsAssignmentFiltered"
import { getIsSingleAssignmentHighlighted } from "../utils/getIsSingleAssignmentHighlighted"

interface Props {
  assignment: GroupedAssignment
  isJobDisabled: boolean
  isJobSelectedInQuickAssignMode: (providerId: number) => boolean
  getAssignmentUnderstaffedText: () => string
  isAssignmentUnderstaffed: boolean | undefined

  showModal?: () => void
}

export const AssignmentMultipleJobsElement = ({
  assignment,
  isJobDisabled,
  isJobSelectedInQuickAssignMode,
  getAssignmentUnderstaffedText,
  showModal,
}: Props) => {
  const {
    highlightOptions: { highlightProviders },
    filterOptions: {
      providersFilters: { providerIds },
    },
    isUnderstaffedJobsHighlightActive,
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  const { selectedProvider } = useSelector(
    (state) => state.calendarEvents.calendarConfig
  )

  const providersAreHighlighted = useMemo(
    () => highlightProviders && highlightProviders.length > 0,
    [highlightProviders]
  )

  const isProviderHighlighted = getIsSingleAssignmentHighlighted(
    assignment,
    highlightProviders
  )

  const isSplitAssignmentHighlighted = getIsMultipleAssignmentHighlighted(
    assignment.additional_split_event_assignments,
    highlightProviders
  )

  const isAdditionalAssignmentHighlighted = getIsMultipleAssignmentHighlighted(
    assignment.additional_event_assignments,
    highlightProviders
  )

  const isAssignmentUnderstaffed = useMemo(() => {
    const currentAssignedCount = assignment.additional_event_assignments
      ? assignment.additional_event_assignments.length + 1
      : 1

    return (
      isUnderstaffedJobsHighlightActive &&
      assignment.job_day_assignment &&
      currentAssignedCount < assignment.job_day_assignment.minimum
    )
  }, [
    isUnderstaffedJobsHighlightActive,
    assignment.additional_event_assignments,
  ])

  const itemIsHighlighted =
    isProviderHighlighted ||
    isSplitAssignmentHighlighted ||
    isAdditionalAssignmentHighlighted ||
    isAssignmentUnderstaffed

  const additionalEventAssignments = assignment?.eventid
    ? assignment.additional_event_assignments
    : assignment.draft_additional_event_assignments

  const isProviderFilterActive = useMemo(
    () => providerIds && providerIds?.length > 0,
    [providerIds]
  )

  const multiAssignmentClasses = cx(css.assignmentWrapper, {
    [css.disabledCellJob]:
      (selectedProvider &&
        (isJobDisabled ||
          isJobSelectedInQuickAssignMode(assignment.provider?.providerid))) ||
      (selectedProvider &&
        !isJobSelectedInQuickAssignMode(assignment.provider?.providerid) &&
        !isJobDisabled),
    [css.highlightedAssignment]: itemIsHighlighted,
    [css.highlightedAssignmentDraft]:
      itemIsHighlighted && assignment?.draft_eventid,
    [css.fadedAssignmentWrapper]:
      (providersAreHighlighted || isUnderstaffedJobsHighlightActive) &&
      !itemIsHighlighted,
    [css.fadedDraftAssignmentWrapper]:
      (providersAreHighlighted || isUnderstaffedJobsHighlightActive) &&
      !itemIsHighlighted &&
      assignment.draft_eventid,
  })

  return (
    <ul className={multiAssignmentClasses}>
      <li>
        <button
          className={cx(css.assignmentElementWrapper, {
            [css.activeCellJob]:
              selectedProvider &&
              isJobSelectedInQuickAssignMode(assignment.provider?.providerid),
            [css.assignmentElementWrapperFaded]:
              providersAreHighlighted &&
              !getAdditionalAssignmentHighlighted(
                assignment.provider?.providerid,
                highlightProviders
              ),
            [css.multipleDisabledCellJob]:
              selectedProvider &&
              !isJobSelectedInQuickAssignMode(assignment.provider?.providerid),
          })}
          style={{
            color: assignment.draft_eventid ? "#A5A5A5" : "",
          }}
          onClick={showModal}
          disabled={
            selectedProvider &&
            !isJobSelectedInQuickAssignMode(assignment.provider?.providerid)
          }
        >
          {isProviderFilterActive &&
          getIsAssignmentFiltered(
            assignment.provider?.providerid,
            providerIds
          ) ? (
            <CustomTooltip
              arrow
              title={
                <div className={css.textContainer}>
                  <AssignmentDetails
                    isDraft={Boolean(assignment.draft_eventid)}
                    providerColor={assignment.provider?.provider_color}
                    providerName={assignment.provider?.display_name}
                  />
                </div>
              }
            >
              <span className={css.filteredText}>Filtered</span>
            </CustomTooltip>
          ) : (
            <AssignmentDetails
              isDraft={Boolean(assignment.draft_eventid)}
              providerColor={assignment.provider?.provider_color}
              providerName={assignment.provider?.display_name}
            />
          )}
        </button>
      </li>

      {Boolean(assignment.split_shiftid) === true && (
        <>
          {assignment.additional_split_event_assignments?.map(
            (splitAssignment) => {
              return (
                <li
                  key={`${splitAssignment.providerid}-${splitAssignment.jobid}-${splitAssignment.eventid}-splitAssignment`}
                >
                  <button
                    disabled={
                      !isJobSelectedInQuickAssignMode(
                        splitAssignment.provider?.providerid
                      )
                    }
                    className={cx(css.assignmentElementWrapper, {
                      [css.activeCellJob]: isJobSelectedInQuickAssignMode(
                        splitAssignment.provider?.providerid
                      ),
                      [css.assignmentElementWrapperFaded]:
                        providersAreHighlighted &&
                        !getAdditionalAssignmentHighlighted(
                          splitAssignment.provider?.providerid,
                          highlightProviders
                        ),
                      [css.multipleDisabledCellJob]:
                        selectedProvider &&
                        !isJobSelectedInQuickAssignMode(
                          splitAssignment.provider?.providerid
                        ),
                    })}
                    style={{
                      color: splitAssignment.draft_eventid ? "#A5A5A5" : "",
                    }}
                    onClick={showModal}
                  >
                    {isProviderFilterActive &&
                    getIsAssignmentFiltered(
                      splitAssignment.provider?.providerid,
                      providerIds
                    ) ? (
                      <CustomTooltip
                        arrow
                        title={
                          <div className={css.textContainer}>
                            <AssignmentDetails
                              isDraft={Boolean(splitAssignment.draft_eventid)}
                              providerColor={
                                splitAssignment.provider?.provider_color
                              }
                              providerName={
                                splitAssignment.provider?.display_name
                              }
                            />
                          </div>
                        }
                      >
                        <span className={css.filteredText}>Filtered</span>
                      </CustomTooltip>
                    ) : (
                      <AssignmentDetails
                        isDraft={Boolean(splitAssignment.draft_eventid)}
                        providerColor={splitAssignment.provider?.provider_color}
                        providerName={splitAssignment.provider?.display_name}
                      />
                    )}
                  </button>
                </li>
              )
            }
          )}
        </>
      )}
      {additionalEventAssignments && additionalEventAssignments.length > 0 && (
        <>
          {additionalEventAssignments.map((addAssignment, index) => (
            <li
              key={`${addAssignment.providerid}-${addAssignment.jobid}-${
                addAssignment.eventid ?? addAssignment?.draft_eventid
              }-${index}`}
            >
              <button
                disabled={
                  !isJobSelectedInQuickAssignMode(
                    addAssignment.provider?.providerid
                  )
                }
                className={cx(css.assignmentElementWrapper, {
                  [css.activeCellJob]: isJobSelectedInQuickAssignMode(
                    addAssignment.provider?.providerid
                  ),
                  [css.assignmentElementWrapperFaded]:
                    providersAreHighlighted &&
                    !getAdditionalAssignmentHighlighted(
                      addAssignment.provider?.providerid,
                      highlightProviders
                    ),
                  [css.multipleDisabledCellJob]:
                    selectedProvider &&
                    !isJobSelectedInQuickAssignMode(
                      addAssignment.provider?.providerid
                    ),
                })}
                style={{
                  color: addAssignment.draft_eventid ? "#A5A5A5" : "",
                }}
                onClick={showModal}
              >
                {isProviderFilterActive &&
                getIsAssignmentFiltered(
                  addAssignment.provider?.providerid,
                  providerIds
                ) ? (
                  <CustomTooltip
                    arrow
                    title={
                      <div className={css.textContainer}>
                        <AssignmentDetails
                          isDraft={Boolean(addAssignment.draft_eventid)}
                          providerColor={addAssignment.provider?.provider_color}
                          providerName={addAssignment.provider?.display_name}
                        />
                      </div>
                    }
                  >
                    <span className={css.filteredText}>Filtered</span>
                  </CustomTooltip>
                ) : (
                  <AssignmentDetails
                    isDraft={Boolean(addAssignment.draft_eventid)}
                    providerColor={addAssignment.provider?.provider_color}
                    providerName={addAssignment.provider?.display_name}
                  />
                )}
              </button>
            </li>
          ))}
        </>
      )}

      {isUnderstaffedJobsHighlightActive && isAssignmentUnderstaffed && (
        <div className={css.understaffedCounter}>
          {getAssignmentUnderstaffedText()}
        </div>
      )}
    </ul>
  )
}
