import React, { useState, MouseEvent, memo, useCallback, useMemo } from "react"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import ProvidersCustomSelectComponent from "../../../ProviderCustomSelect/ProvidersCustomSelect"
import SplitShiftDetail from "../SplitShiftDetail"
import AdditionalAssignmentsSelect from "../AdditionalAssignments/AdditionalAssignmentsSelect"
import AdditionalAssignments from "../AdditionalAssignments/AdditionalAssignments"
import MultipleProvidersShiftTime from "../MultipleProvidersShiftTime"
import SplitShiftTime from "../SplitShiftTime"
import FlagDetail from "./FlagDetail"
import {
  MultipleAssigmentJob,
  TransformedProvider,
} from "@app/containers/spa/WhiteboardCalendar/data"
import css from "./JobRow.module.scss"
import Popover from "@material-ui/core/Popover"
import api from "@app/services/api"
import SplitShiftEditor from "../../../SplitShiftEditor"
import { useCaniuseFeature } from "@app/utils/hooks"
import { useSelector } from "@app/models"
import { DefaultCalendarColor } from "@app/utils/constants"
import { Control, useController } from "react-hook-form"
import {
  FormData,
  FormElement,
} from "@app/containers/spa/WhiteboardCalendar/component/JobsTableModal/hooks/jobsTableUseForm"

interface JobRowProps {
  item: MultipleAssigmentJob
  rowIndex: number
  JobsTableHeader: string[]
  headerKeyMap: { [key: string]: keyof MultipleAssigmentJob }
  date: string
  expandedRows: Record<number, boolean>
  toggleExpandedRow: (rowIndex: number) => void
  expandedSplitRows: Record<number, boolean>
  toggleExpandedSplitRow: (rowIndex: number) => void
  defaultProviders: TransformedProvider[]
  setRuledProvidersMappings: React.Dispatch<React.SetStateAction<any>>
  setSelectedJobId: React.Dispatch<React.SetStateAction<number | undefined>>
  setDraftToPublish: React.Dispatch<
    React.SetStateAction<MultipleAssigmentJob | undefined>
  >
  currentDate: string
  focusedJobId: number | null
  name: `formElements.${number}`
  control: Control<FormData>
  setOnSplitCount: React.Dispatch<React.SetStateAction<number>>
  setAdditionalAssignmentsEnabled: (enabled: boolean) => void
}

const JobRow: React.FC<JobRowProps> = ({
  item,
  rowIndex,
  JobsTableHeader,
  headerKeyMap,
  date,
  expandedRows,
  toggleExpandedRow,
  expandedSplitRows,
  toggleExpandedSplitRow,
  defaultProviders,
  setRuledProvidersMappings,
  setSelectedJobId,
  setDraftToPublish,
  currentDate,
  focusedJobId,
  name,
  control,
  setOnSplitCount,
  setAdditionalAssignmentsEnabled,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isFlagged, setIsFlagged] = useState(item.flag)
  const [isSplited, setIsSplited] = useState(false)
  const [
    toggleExpandedAdditionalAssignmentRow,
    setToggleExpandedAdditionalAssignmentRow,
  ] = useState(false)
  const [isTempFlagged, setIsTempFlagged] = useState(item.flag)
  const [providerSelected, setProviderSelected] = useState<boolean>(false)
  const { jobs } = useSelector((state) => state.groupData)
  const { draft_mode_scheduling } = useSelector(
    (state) => state.calendarEvents.calendarConfig.rulesConfig
  )

  const splitShiftEnabled = useCaniuseFeature("split_shift", { scope: "group" })

  const {
    field: { onChange, value },
  } = useController({ name, control })

  const splitShiftAvailable =
    splitShiftEnabled && item.status !== "Draft" && !item.multiple_assignments

  const shouldShowFlag =
    item.status !== "Published" &&
    item.status !== "Draft" &&
    !isFlagged &&
    !providerSelected

  const publishAvailable = item.status === "Draft"
  const isFocused = focusedJobId === Number(item.jobid)

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const multipleAssignmentSettings = useMemo(() => {
    return (
      jobs
        .find((job) => job.jobid === Number(item.jobid))
        ?.multipleAssignmentsSettings?.find(
          (setting) => setting.date === date
        ) || {
        jobid: Number(item.jobid),
        date,
        minimum: 0,
        ideal: 0,
        maximum: 0,
        dayid: 0,
      }
    )
  }, [item, jobs])

  const multipleAssignmentsEnabled =
    value.type === "draft_multiple_assignments" ||
    value.type === "multiple_assignments" ||
    (value.type === "unassigned" && multipleAssignmentSettings.maximum > 1)

  const handleFlagClick = useCallback(async () => {
    if (!item.providerid || isFlagged) {
      setIsTempFlagged((prev) => !prev)

      if (isFlagged) {
        try {
          await api.deleteFlag(currentDate, Number(item.jobid))
          setIsFlagged(false)
          setIsTempFlagged(false)
        } catch (error) {
          setIsTempFlagged(true)
        }
      } else {
        try {
          const response = await api.createFlag(currentDate, Number(item.jobid))
          if (response) {
            setIsFlagged(true)
            setIsTempFlagged(true)
          }
        } catch (error) {
          setIsTempFlagged(false)
        }
      }
    }
  }, [currentDate, isFlagged, item.jobid, item.providerid])

  const handleSplitShiftClick = () => {
    setOnSplitCount((prev) => (isSplited ? prev - 1 : prev + 1))
    setIsSplited((prev) => !prev)
    handleClose()
  }

  const handlePublish = () => {
    setDraftToPublish(item)
  }

  const handleProviderChangeAndFlagRemoval = useCallback(
    async (data: FormElement) => {
      onChange(data)
      setIsTempFlagged(false)
      setProviderSelected(true)
    },
    [onChange]
  )

  const handleSingleProviderChange = useCallback(
    (providerid: number, jobid: string, draft: boolean) => {
      handleProviderChangeAndFlagRemoval({
        type: draft ? "draft_event" : "event",
        data: {
          providerid: providerid,
          jobid: Number(jobid),
          edate: date,
        },
      })
    },
    [handleProviderChangeAndFlagRemoval, date]
  )

  const handleMenuItemClick = useCallback(
    (option: string) => {
      if (option === "Flag") {
        handleFlagClick()
      } else if (option === "Publish") {
        handlePublish()
      }
      handleClose()
    },
    [handleClose, handleFlagClick, item]
  )

  const handleToggleAdditionalAssignments = () => {
    setToggleExpandedAdditionalAssignmentRow(
      (toggleExpandedAdditionalAssignmentRow) => {
        setAdditionalAssignmentsEnabled(!toggleExpandedAdditionalAssignmentRow)
        return !toggleExpandedAdditionalAssignmentRow
      }
    )
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const providerHeader = (item: MultipleAssigmentJob) => {
    return value.type === "event" || value.type == "draft_event" ? (
      <ProvidersCustomSelectComponent
        allowUnavailable
        resetSelectionTrigger={0}
        jobid={item?.jobid}
        edate={item?.edate}
        defaultProviders={defaultProviders}
        setRuledProvidersMappings={setRuledProvidersMappings}
        onChange={(id) =>
          handleSingleProviderChange(id, item.jobid, item.status === "Draft")
        }
        defaultProviderId={`${value.data.providerid}`}
      />
    ) : value.type === "unassigned" &&
      multipleAssignmentSettings.maximum === 1 ? (
      <ProvidersCustomSelectComponent
        allowUnavailable
        resetSelectionTrigger={0}
        jobid={item?.jobid}
        edate={date}
        defaultProviders={defaultProviders}
        setRuledProvidersMappings={setRuledProvidersMappings}
        onChange={(id) =>
          handleSingleProviderChange(id, item.jobid, draft_mode_scheduling)
        }
        defaultProviderId=""
      />
    ) : value.type === "split_shift" ? (
      <SplitShiftDetail
        item={item}
        rowIndex={rowIndex}
        expandedSplitRows={expandedSplitRows}
        defaultProviders={defaultProviders}
        value={value}
        setRuledProvidersMappings={setRuledProvidersMappings}
        handleProviderChange={handleProviderChangeAndFlagRemoval}
        handleClick={handleSplitShiftClick}
      />
    ) : multipleAssignmentsEnabled ? (
      <AdditionalAssignmentsSelect
        providersAssigned={Array.isArray(value.data) ? value.data.length : 0}
        toggleExpandedAdditionalAssignmentRow={
          toggleExpandedAdditionalAssignmentRow
        }
        handleToggleAdditionalAssignments={handleToggleAdditionalAssignments}
      />
    ) : null
  }

  return (
    <>
      <tr
        id={`job-${item.jobid}`}
        tabIndex={isFocused ? -1 : undefined}
        className={`${css.rowClass} ${isFocused ? css.focusedRow : ""}`}
        key={item.jobid}
      >
        {JobsTableHeader.map((header, index) => {
          const itemKey = headerKeyMap[header]
          const style =
            index === 0
              ? {
                  borderLeft: `10px solid  ${
                    item.color || DefaultCalendarColor
                  }`,
                }
              : {}
          const tdClass =
            header === "Job"
              ? css.jobColumn
              : header === "Provider"
              ? css.providerColumn
              : ""
          let content
          if (header === "Job") {
            content = (
              <a
                className={css.selectedJobLink}
                onClick={() => setSelectedJobId(Number(item.jobid))}
              >
                {item.job}
              </a>
            )
          } else if (header === "Provider") {
            content = providerHeader(item)
          } else if (header === "Flag") {
            content = (
              <FlagDetail
                item={{ ...item, flag: isTempFlagged }}
                date={currentDate}
                onFlagClick={handleFlagClick}
                shouldShowFlag={shouldShowFlag}
              />
            )
          } else if (header === "Splits") {
            content = item[itemKey] === 0 ? "-" : item[itemKey]
          } else if (header === "Max") {
            content = multipleAssignmentSettings.maximum
          } else if (
            header === "Shift Time" &&
            item.assignments !== 0 &&
            item.assignments > 0
          ) {
            content = (
              <MultipleProvidersShiftTime
                item={item}
                rowIndex={rowIndex}
                expandedRows={expandedRows}
                toggleExpandedRow={toggleExpandedRow}
              />
            )
          } else if (header === "Shift Time" && item.split_shift.length > 0) {
            content = (
              <SplitShiftTime
                item={item}
                rowIndex={rowIndex}
                expandedSplitRows={expandedSplitRows}
                toggleExpandedSplitRow={toggleExpandedSplitRow}
              />
            )
          } else {
            content = item[itemKey]
          }
          return (
            <td key={`${rowIndex}-${header}`} style={style} className={tdClass}>
              {content}
            </td>
          )
        })}
        <td>
          <MoreHorizIcon
            className={css.tableOptions}
            onClick={handleClick as any}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            classes={{ paper: css.popover }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className={css.menu}>
              {splitShiftAvailable && (
                <div className={css.menuItem} onClick={handleSplitShiftClick}>
                  Split Shift
                </div>
              )}
              {shouldShowFlag && (
                <div
                  className={css.menuItem}
                  onClick={() => handleMenuItemClick("Flag")}
                >
                  Flag
                </div>
              )}
              {publishAvailable && (
                <div
                  className={css.menuItem}
                  onClick={() => handleMenuItemClick("Publish")}
                >
                  Publish
                </div>
              )}
              <div
                className={css.menuItem}
                style={{ color: "red" }}
                onClick={() => handleMenuItemClick("Delete")}
              >
                Delete
              </div>
            </div>
          </Popover>
        </td>
      </tr>
      {item && isSplited && (
        <tr className={css.splitShiftPanel}>
          <td colSpan={9}>
            <SplitShiftEditor
              jobid={Number(item.jobid)}
              assignments={item.assignments}
              edate={date}
              splitShifts={item.split_shift}
              onChange={onChange}
              hideSplits={() => {
                setOnSplitCount((prev) => prev - 1)
                setIsSplited(false)
              }}
            />
          </td>
        </tr>
      )}
      {item &&
        toggleExpandedAdditionalAssignmentRow &&
        multipleAssignmentsEnabled && (
          <AdditionalAssignments
            value={value}
            item={item}
            defaultProviders={defaultProviders}
            setRuledProvidersMappings={setRuledProvidersMappings}
            multipleAssignmentSetting={multipleAssignmentSettings}
            handleProviderChange={handleProviderChangeAndFlagRemoval}
            handleToggleAdditionalAssignments={
              handleToggleAdditionalAssignments
            }
          />
        )}
    </>
  )
}

export default memo(JobRow)
