import { Provider } from "../containers/spa/WhiteboardCalendar/data"

export const getAdditionalAssignmentHighlighted = (
  providerId: number,
  highlightProviders: Provider[] | undefined
) => {
  return highlightProviders?.some(
    (highlightedProvider) => providerId === highlightedProvider.providerid
  )
}
