import api from "@app/services/api"
import { handleApiError } from "@app/utils"

export const updateAssignment = (
  providerids: number[],
  previousAssignment: AssignmentBaseType,
  hideModal: () => void
) => {
  const additionalEventAssignments = previousAssignment.eventid
    ? previousAssignment.additional_event_assignments
    : previousAssignment.draft_additional_event_assignments
  if (!previousAssignment || providerids.length === 0) {
    console.error("No assignment data to create")
    return
  } else {
    if (!previousAssignment) {
      return
    } else if (
      !previousAssignment.split_shift &&
      !previousAssignment?.additional_event_assignments?.length &&
      providerids.length === 1
    ) {
      if (previousAssignment.eventid) {
        api
          .updateSingleAssignment(previousAssignment.eventid, providerids[0])
          .then(
            (res) => {
              hideModal()
            },
            (res) => handleApiError(res.data.response)
          )
      } else {
        api
          .updateDraftEvent({
            jobid: previousAssignment.jobid,
            providerid: providerids[0],
            edate: previousAssignment.edate,
          })
          .then((res) => {
            hideModal()
          }, handleApiError)
      }
    } else if (additionalEventAssignments?.length || providerids.length > 1) {
      const updateMultipleAssignments = previousAssignment.draft_eventid
        ? api.updateDraftMultipleAssignments
        : api.updateMultipleAssignments
      updateMultipleAssignments(
        previousAssignment.jobid,
        previousAssignment.edate,
        providerids
      ).then(
        (res) => {
          hideModal()
        },
        (res) => handleApiError(res.data.response)
      )
    }
  }
}

export const createSingleAssignment = (
  providerids: number[],
  jobid: number,
  edate: string,
  hideModal: () => void,
  draftMode: boolean
) => {
  if (providerids.length === 0) {
    console.error("No assignment data to create")
    return
  } else {
    if (providerids.length === 1) {
      api.createSingleAssignment(edate, jobid, providerids[0], draftMode).then(
        (res) => {
          hideModal()
        },
        (res) => handleApiError(res.data.response)
      )
    } else if (providerids.length > 1) {
      const multipleAssignmentsApi = draftMode
        ? api.createDraftMultipleAssignemnts
        : api.createMultipleAssignemnts
      multipleAssignmentsApi(jobid, edate, providerids).then(
        (res) => {
          hideModal()
        },
        (res) => handleApiError(res.data.response)
      )
    }
  }
}
